'use strict';

// Constants
var SELECTORS = {
    CHILDREN:
        '.form-group.required.comm-form__input > input, .form-group.required.comm-form__textarea > textarea, .form-group.required.comm-form__select > select',
    SELECT2_MULTIPLE: 'select[data-is-select=true][data-select-options*="multiple"]'
};

/**
 * Apply the 'active' class based on input value.
 * @param  {jQuery<HTMLElement>} $form - jQuery object representing a form element.
 */
function setActiveState($form) {
    var isFilled = true;

    // eslint-disable-next-line consistent-return
    $form.find(SELECTORS.CHILDREN, SELECTORS.SELECT2_MULTIPLE).each(function () {
        var value = $(this).val();
        var select2Value = $(this).val();

        if (
            value === null || (typeof value === 'string' && value.trim() === '') || !select2Value || select2Value.length === 0) {
            isFilled = false;
            return false;
        }
    });

    $form.find('button[type=submit]').prop('disabled', !isFilled);
}

/**
 * Apply the 'active' class based on input value.
 */
function setEventListeners() {
    /**
     * Handles input events on forms with data-validate-required-fields=true attribute.
     */
    $('body').on('input', 'form[data-validate-required-fields=true]', function () {
        setActiveState($(this));
    });

    /**
     * Handles the select2 multi-choice events (select and unselect options).
     */
    $('body').on('select2:select select2:unselect', SELECTORS.SELECT2_MULTIPLE, function () {
        setActiveState($(this));
    });
}

module.exports = {
    setEventListeners: setEventListeners
};

/**
 * Collection of all datepicker component related scripts
 */
var datepicker = {};

// Model Includes
var DatePicker = require('../model/DatePickerModel');

// Constants
var ATTRIBUTES = {
    DATA: {
        IS_DATE_PICKER: 'data-is-datepicker',
        IS_DATE_RANGE: 'data-is-daterange',
        HAS_TIME: 'data-datepicker-has-time',
        IS_LOADED: 'data-is-daterange-loaded'
    }
};

/**
 * Create and define de datepicker behavior.
 * @param {null | undefined | JQuery<HTMLElement>} _$datePickers - initialize date range pickers.
 *                                                   If non is provider, then it will initialize all date pickers.
 */
datepicker.init = function (_$datePickers) {
    // Constants
    var $datePickers = $(`[${ATTRIBUTES.DATA.IS_DATE_PICKER}=true]:not([${ATTRIBUTES.DATA.IS_LOADED}=true])`);

    // Get date pickers to initialize
    if (_$datePickers !== null && _$datePickers !== undefined && _$datePickers.length) {
        $datePickers = _$datePickers;
    }

    // Initialize window global variable that stores all custom date pickers active in page
    if (!('commCustomDatePickers' in window) || !Array.isArray(window.commCustomDatePickers)) {
        window.commCustomDatePickers = [];
    }

    $datePickers.each(function (index, element) {
        // Constants
        const $ELEMENT = $(element);
        const IS_DATE_RANGE = $ELEMENT.attr(ATTRIBUTES.DATA.IS_DATE_RANGE) === 'true';
        const HAS_TIME = $ELEMENT.attr(ATTRIBUTES.DATA.HAS_TIME) === 'true';

        // Create a new DatePicker instance
        const model = new DatePicker($ELEMENT, { isRange: IS_DATE_RANGE, hasTime: HAS_TIME });

        model.attachEventListeners();
        window.commCustomDatePickers.push(model);
        $ELEMENT.attr(ATTRIBUTES.DATA.IS_LOADED, 'true');
    });
};

module.exports = datepicker;

/* eslint-disable no-param-reassign */

// Module Includes
// More information at: https://momentjs.com/docs/
var moment = require('moment');

// Helper Includes
var helpers = {
    viewport: require('../utilities/viewport')
};

// Constant Includes
var CONSTANTS = {
    BREAKPOINTS: require('../constants/breakpoints'),
    MODAL: require('ana/constants/modals')
};

// Constants
var ATTRIBUTES = {
    DATA: {
        CONTAINER: 'data-datepicker-container',
        END: 'data-is-daterange-end',
        END_DATE: 'data-datepicker-end-date',
        FORMAT_SHORT: 'data-datepicker-format-short',
        FORMAT_LONG: 'data-datepicker-format-long',
        ID: 'data-datepicker-id',
        LOCALE: 'data-locale',
        RESOURCE_APPLY: 'data-apply-resource',
        RESOURCE_CANCEL: 'data-cancel-resource',
        START: 'data-is-daterange-start',
        START_DATE: 'data-datepicker-start-date',
        MIN_DATE: 'data-datepicker-min-date',
        MAX_DATE: 'data-datepicker-max-date',
        DAILY_MIN_DATE: 'data-datepicker-daily-min-hours',
        DAILY_MAX_DATE: 'data-datepicker-daily-max-hours',
        URL: 'data-url',
        MIN_REQUIRED_TIME: 'data-datepicker-min-reservation-time',
        MAX_REQUIRED_RESERVATION_DURATION: 'data-datepicker-max-reservation-duration',
        MIN_REQUIRED_TIME_ERROR: 'data-datepicker-min-reservation-error',
        MAX_REQUIRED_RESERVATION_DURATION_ERROR: 'data-datepicker-max-reservation-duration-error',
        FULL: 'data-is-daterange-full',
        ALLOW_MIN_DATE_BEFORE_CURRENT: 'data-datepicker-allow-min-date-before',
        LINKED_CALENDARS: 'data-datepicker-linked-calendars',
        SHOW_DROPDOWNS: 'data-datepicker-show-dropdowns',
        EMPTY_INPUT: 'data-empty-input',
        EMPTY_INPUT_PLACEHOLDER: 'data-empty-input-placeholder'
    }
};
var LOGS = {
    ERROR: {
        LOCALE: 'No selected locale found!',
        SELECTOR: 'Please provide a valid selector to create a DatePicker instance!'
    }
};
var SELECTORS = {
    SELECTED_LOCALE: '.country-selector .dropdown-item.active',
    SHORT_START_DATE: '.comm-search__nav__datepicker__dates__start__short',
    LONG_START_DATE: '.comm-search__nav__datepicker__dates__start__long',
    SHORT_END_DATE: '.comm-search__nav__datepicker__dates__end__short',
    LONG_END_DATE: '.comm-search__nav__datepicker__dates__end__long'
};
var PAGE_IDS = {
    SEARCH: 'searchpage'
};

// Variables
var locale;
var dateFormat;
var dateFormatNoTime;

/**
 * Get date range picker ID that is defined by the element's data attribute.
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} date range picker id
 */
function getID($element) {
    return $element.attr(ATTRIBUTES.DATA.ID);
}

/**
 * Get date range picker redirect url that is defined by the element's data attribute.
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} date range picker redirect url
 */
function getURL($element) {
    return $element.attr(ATTRIBUTES.DATA.URL);
}

/**
 * Round date by 15 min.
 * @param {moment} _momentDate - momentjs date instance
 * @param {number} minutes - date time minutes
 */
function setRoundedMinutes(_momentDate, minutes) {
    if (minutes === 0) {
        return;
    } else if (minutes <= 15) {
        _momentDate.set('minutes', 15);
    } else if (minutes <= 30) {
        _momentDate.set('minutes', 30);
    } else if (minutes <= 45) {
        _momentDate.set('minutes', 45);
    } else {
        _momentDate.add(moment.duration(1, 'hour')).startOf('hour');
    }
}

/**
 * Set date format based on locale.
 */
function setDateFormat() {
    switch (locale) {
        case 'en':
            dateFormat = 'MM/DD/YY [-] LT';
            dateFormatNoTime = 'MM/DD/YY';
            break;
        default:
            dateFormat = 'DD/MM/YY [-] LT';
            dateFormatNoTime = 'DD/MM/YY';
            break;
    }
}

/**
 * Set moment locale to get the right calendar configurations.
 * Access https://momentjs.com/ for more information regarding moment.
 * @returns {undefined} if there is an error
 */
function setLocale() {
    // Constants
    const $SELECTED_LOCALE = $(SELECTORS.SELECTED_LOCALE);

    if ($SELECTED_LOCALE.length === 0) {
        return;
    }

    locale = $SELECTED_LOCALE.attr(ATTRIBUTES.DATA.LOCALE);

    if (locale.indexOf('_') !== -1) {
        locale = locale.split('_')[0];
    }

    setDateFormat(locale);
    moment.locale(locale);
}

/**
 * Set date picker object instance.
 * @param {string} selector - selector to get datepicker HTML element
 * @param {boolean} isDateRange - true if is a date range picker
 * @param {Object | null} $inputs - start and end dates inputs
 * @param {Object | null} $spans - start and end dates span elements
 * @param {string} ID - date picker ID
 * @param {boolean} hasTime - true if the date picker has time
 * @param {Object | null} _dailyHours - min and max daily hours
 * @param {Object | null} format - date format
 * @param {boolean} isLinkedCalendars - true if is suppose to use linked calendars
 * @param {boolean} isShowDropdowns - true if is suppose to use show year and months dropdowns
 * @returns {undefined} if there is an error
 */
function getDateRangePicker(
    selector,
    isDateRange,
    $inputs,
    $spans,
    ID,
    hasTime,
    _dailyHours,
    format,
    isLinkedCalendars,
    isShowDropdowns
) {
    // Constants
    const $DATE_PICKER = $(selector);
    const $CONTAINER = $(`[${ATTRIBUTES.DATA.CONTAINER}=${ID}]`);
    const CURRENT_TIME = moment().utc();
    const CURRENT_MINUTES = CURRENT_TIME.minutes();
    const APPLY_LABEL = $DATE_PICKER.attr(ATTRIBUTES.DATA.RESOURCE_APPLY);
    const CANCEL_LABEL = $DATE_PICKER.attr(ATTRIBUTES.DATA.RESOURCE_CANCEL);
    const START_DATE_ATTR = decodeURIComponent($DATE_PICKER.attr(ATTRIBUTES.DATA.START_DATE));
    const END_DATE_ATTR = decodeURIComponent($DATE_PICKER.attr(ATTRIBUTES.DATA.END_DATE));
    const MIN_DATE_ATTR = $DATE_PICKER.attr(ATTRIBUTES.DATA.MIN_DATE);
    const MAX_DATE_ATTR = $DATE_PICKER.attr(ATTRIBUTES.DATA.MAX_DATE);
    const ALLOW_MIN_DATE_BEFORE_CURRENT_ATTR = $DATE_PICKER.attr(ATTRIBUTES.DATA.ALLOW_MIN_DATE_BEFORE_CURRENT);
    const MIN_RESERVATION_TIME = $DATE_PICKER.attr(ATTRIBUTES.DATA.MIN_REQUIRED_TIME);
    const EMPTY_INPUT = $DATE_PICKER.attr(ATTRIBUTES.DATA.EMPTY_INPUT);
    const EMPTY_INPUT_PLACEHOLDER = $DATE_PICKER.attr(ATTRIBUTES.DATA.EMPTY_INPUT_PLACEHOLDER);

    // Variables
    var minDate;
    var maxDate;
    var endDate;
    var startDate;
    var datePicker;
    var minDateAttr;
    var maxDateAttr;
    var dailyHours = _dailyHours !== null && _dailyHours !== undefined ? _dailyHours : { min: null, max: null };
    var timeDifference;
    var timeToAdd;
    var months = [];
    var isEmptyInput = EMPTY_INPUT !== null && EMPTY_INPUT !== undefined && EMPTY_INPUT === 'true';

    // 1. Get minimum and maximum dates
    // 1.1 Minimum date
    minDateAttr =
        MIN_DATE_ATTR !== null &&
        MIN_DATE_ATTR !== undefined &&
        MIN_DATE_ATTR !== 'null' &&
        MIN_DATE_ATTR !== 'undefined'
            ? moment(MIN_DATE_ATTR).utc(true)
            : null;

    setRoundedMinutes(CURRENT_TIME, CURRENT_MINUTES);
    minDate = CURRENT_TIME;

    if (minDateAttr !== null) {
        setRoundedMinutes(minDateAttr, minDateAttr.get('minutes'));

        if (ALLOW_MIN_DATE_BEFORE_CURRENT_ATTR === 'true' || minDateAttr.isAfter(minDate)) {
            minDate = minDateAttr;
        }
    }

    // 1.2 Remove hours for produts without hours
    if (!hasTime) {
        minDate.hours(0);
        minDate.minutes(0);
        minDate.seconds(0);
        minDate.milliseconds(0);
    }

    // 1.3 Maximum date
    maxDateAttr =
        MAX_DATE_ATTR !== null &&
        MAX_DATE_ATTR !== undefined &&
        MAX_DATE_ATTR !== 'null' &&
        MAX_DATE_ATTR !== 'undefined'
            ? moment(MAX_DATE_ATTR).utc(true)
            : null;

    maxDate = maxDateAttr !== null && maxDateAttr.isAfter(minDate) ? maxDateAttr : undefined;

    // 2. Get start and end dates
    // 2.1 Start date
    startDate =
        START_DATE_ATTR !== null &&
        START_DATE_ATTR !== undefined &&
        START_DATE_ATTR !== 'null' &&
        START_DATE_ATTR !== 'undefined'
            ? moment(START_DATE_ATTR).utc()
            : minDate;

    setRoundedMinutes(startDate, startDate.get('minutes'));

    if (startDate.isBefore(minDate)) {
        startDate = minDate;
    }

    if (dailyHours.min !== null && startDate.get('hour') < dailyHours.min) {
        startDate.hour(dailyHours.min);
        startDate.minute(0);
    }

    if (dailyHours.max !== null && startDate.get('hour') > dailyHours.max) {
        startDate.add(moment.duration(1, 'day')).startOf('day').utc();
        startDate.hour(dailyHours.min);
        startDate.minute(0);
    }

    // 2.2 End date
    if (
        END_DATE_ATTR !== null &&
        END_DATE_ATTR !== undefined &&
        END_DATE_ATTR !== 'null' &&
        END_DATE_ATTR !== 'undefined'
    ) {
        endDate = moment(END_DATE_ATTR).utc();
    } else if (ID === PAGE_IDS.SEARCH && !isDateRange) {
        endDate = '';
    } else {
        endDate = moment(startDate).add(moment.duration(1, 'day')).startOf('day').utc();
    }

    if (endDate && endDate.isBefore(minDate)) {
        endDate = minDate;
    }

    // 2.3 Validate if product has minimum reservation time configured and if time between dates is valid, otherwise, update end date
    if (
        endDate &&
        MIN_RESERVATION_TIME !== null &&
        MIN_RESERVATION_TIME !== undefined &&
        MIN_RESERVATION_TIME !== 'null' &&
        MIN_RESERVATION_TIME !== 'undefined' &&
        MIN_RESERVATION_TIME > 0
    ) {
        timeDifference = moment(endDate).diff(moment(startDate), 'minutes');

        if (timeDifference < MIN_RESERVATION_TIME) {
            timeToAdd = MIN_RESERVATION_TIME - timeDifference;
            endDate = moment(endDate).add(timeToAdd, 'minutes');
        }
    }

    // 3. Set calendar month values
    if ($DATE_PICKER.attr(ATTRIBUTES.DATA.SHOW_DROPDOWNS)) {
        moment.months().forEach(function (value) {
            months.push(`${value}`);
        });
    } else {
        moment.months().forEach(function (value) {
            months.push(`${value},`);
        });
    }

    // 4. Create date range picker
    datePicker = $DATE_PICKER.daterangepicker({
        autoUpdateInput: true,
        drops: 'down',
        locale: {
            format: moment.defaultFormatUtc,
            daysOfWeek: moment.weekdaysShort(),
            monthNames: months,
            applyLabel: APPLY_LABEL || undefined,
            cancelLabel: CANCEL_LABEL || undefined
        },
        minDate: minDate,
        maxDate: maxDate,
        startDate: startDate,
        endDate: endDate,
        singleDatePicker: !isDateRange,
        timePicker: hasTime,
        timePickerIncrement: 15,
        timePicker24Hour: true,
        parentEl: $CONTAINER || undefined,
        linkedCalendars: helpers.viewport.getViewportSize() !== CONSTANTS.BREAKPOINTS.MOBILE.ID && isLinkedCalendars,
        showDropdowns: isShowDropdowns
    });

    // 5. Set start, end and full input dates
    if ($inputs !== null && 'start' in $inputs && $inputs.start !== null && $inputs.start !== undefined) {
        if (isEmptyInput && EMPTY_INPUT_PLACEHOLDER.length > 0) {
            $inputs.start.val(EMPTY_INPUT_PLACEHOLDER);
        } else {
            $inputs.start.val(startDate.format(format.short));
        }
    }

    if (endDate && $inputs !== null && 'end' in $inputs && $inputs.end !== null && $inputs.end !== undefined) {
        if (isEmptyInput && EMPTY_INPUT_PLACEHOLDER.length > 0) {
            $inputs.end.val(EMPTY_INPUT_PLACEHOLDER);
        } else {
            $inputs.end.val(endDate.format(format.short));
        }
    }

    if ($inputs !== null && 'full' in $inputs && $inputs.full !== null && $inputs.full !== undefined) {
        if (endDate) {
            $inputs.full.val(`${startDate.format(format.short)} - ${endDate.format(format.short)}`);
        } else {
            $inputs.full.val(startDate.format(format.short));
        }
    }

    // 6. Set start and end span dates
    if ($spans !== null && 'start' in $spans && $spans.start !== null && $spans.start !== undefined) {
        if (isEmptyInput && EMPTY_INPUT_PLACEHOLDER.length > 0) {
            $spans.start.find(SELECTORS.SHORT_START_DATE).html(EMPTY_INPUT_PLACEHOLDER);
            $spans.start.find(SELECTORS.LONG_START_DATE).html(EMPTY_INPUT_PLACEHOLDER);
        } else {
            $spans.start.find(SELECTORS.SHORT_START_DATE).html(startDate.format(format.short));
            $spans.start.find(SELECTORS.LONG_START_DATE).html(startDate.format(format.long));
        }
    }

    if (endDate && $spans !== null && 'end' in $spans && $spans.end !== null && $spans.end !== undefined) {
        if (isEmptyInput && EMPTY_INPUT_PLACEHOLDER.length > 0) {
            $spans.end.find(SELECTORS.SHORT_END_DATE).html(EMPTY_INPUT_PLACEHOLDER);
            $spans.end.find(SELECTORS.LONG_END_DATE).html(EMPTY_INPUT_PLACEHOLDER);
        } else {
            $spans.end.find(SELECTORS.SHORT_END_DATE).html(endDate.format(format.short));
            $spans.end.find(SELECTORS.LONG_END_DATE).html(endDate.format(format.long));
        }
    }

    return datePicker.data('daterangepicker');
}

/**
 * Get date range picker date formats.
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @param {boolean} hasTime - true if the date picker has time
 * @returns {string} date range picker date format
 */
function getFormat($element, hasTime) {
    // Variables
    var result = {
        short: null,
        long: null
    };
    const FORMAT_SHORT = $element.attr(ATTRIBUTES.DATA.FORMAT_SHORT);
    const FORMAT_LONG = $element.attr(ATTRIBUTES.DATA.FORMAT_LONG);

    try {
        if (
            FORMAT_SHORT !== null &&
            FORMAT_SHORT !== undefined &&
            FORMAT_SHORT !== null &&
            FORMAT_SHORT !== undefined
        ) {
            result.short = FORMAT_SHORT;
        } else if (hasTime) {
            result.short = dateFormat;
        } else {
            result.short = dateFormatNoTime;
        }

        // 4.2. Long format
        if (FORMAT_LONG !== null && FORMAT_LONG !== undefined && FORMAT_LONG !== null && FORMAT_LONG !== undefined) {
            result.long = FORMAT_LONG;
        } else if (hasTime) {
            result.long = dateFormat;
        } else {
            result.long = dateFormatNoTime;
        }
    } catch (error) {
        console.error(error);
    }

    return result;
}

/**
 * Get start and end inputs.
 * @param {string} ID - date picker ID
 * @param {boolean} isDateRange - true if is a date range picker
 * @returns {Object | null} undefined if not valid, start and end dates otherwise
 */
function getInputs(ID, isDateRange) {
    // Variables
    var start;
    var end;
    var full;

    if (ID === null) {
        return null;
    }

    start = $(`input[${ATTRIBUTES.DATA.START}][${ATTRIBUTES.DATA.ID}=${ID}]`);

    if (isDateRange) {
        end = $(`input[${ATTRIBUTES.DATA.END}][${ATTRIBUTES.DATA.ID}=${ID}]`);
    }

    if (
        start !== undefined &&
        start !== null &&
        start.length === 0 &&
        end !== undefined &&
        end !== null &&
        end.length === 0
    ) {
        full = $(`input[${ATTRIBUTES.DATA.FULL}][${ATTRIBUTES.DATA.ID}=${ID}]`);

        if (full !== undefined && full !== null && full.length === 0) {
            return null;
        }

        return {
            full: full
        };
    }

    return {
        start: start,
        end: end
    };
}

/**
 * Get start and end span elements.
 * @param {string} ID - date picker ID
 * @param {boolean} isDateRange - true if is a date range picker
 * @returns {Object | null} undefined if not valid, start and end dates otherwise
 */
function getSpanElements(ID, isDateRange) {
    // Variables
    var start;
    var end;

    if (ID === null) {
        return null;
    }

    start = $(`span[${ATTRIBUTES.DATA.START}][${ATTRIBUTES.DATA.ID}=${ID}]`);

    if (isDateRange) {
        end = $(`span[${ATTRIBUTES.DATA.END}][${ATTRIBUTES.DATA.ID}=${ID}]`);
    }

    if (
        start !== undefined &&
        start !== null &&
        start.length === 0 &&
        end !== undefined &&
        end !== null &&
        end.length === 0
    ) {
        return null;
    }

    return {
        start: start,
        end: end
    };
}

/**
 * Get date range picker daily min and max hours by the element's data attribute.
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} daily min and max hours
 */
function getDailyHours($element) {
    // Variables
    var result = {
        min: null,
        max: null
    };
    var min = $element.attr(ATTRIBUTES.DATA.DAILY_MIN_DATE);
    var max = $element.attr(ATTRIBUTES.DATA.DAILY_MAX_DATE);

    try {
        if (min !== 'null' && min !== 'undefined' && min !== null && min !== undefined) {
            result.min = parseInt(min, 10);
        }
        if (max !== 'null' && max !== 'undefined' && max !== null && max !== undefined) {
            result.max = parseInt(max, 10);
        }
    } catch (error) {
        console.error(error);
    }

    return result;
}

/**
 * Get date range picker dates by getting the element's data attribute values.
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} date picker dates
 */
function getDates($element) {
    return {
        start: $element.attr(ATTRIBUTES.DATA.START_DATE) || null,
        end: $element.attr(ATTRIBUTES.DATA.END_DATE) || null
    };
}

/**
 * Get minimum reservation time from the element's data attribute values
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} minimum reservation time
 */
function getMinimumReservationTime($element) {
    return $element.attr(ATTRIBUTES.DATA.MIN_REQUIRED_TIME);
}

/**
 * Get maximum reservation duration from the element's data attribute values
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} maximum reservation duration
 */
function getMaximumReservationDurationTime($element) {
    return $element.attr(ATTRIBUTES.DATA.MAX_REQUIRED_RESERVATION_DURATION);
}

/**
 * Get minimum reservation time error message from the element's data attribute values
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} minimum reservation time error message
 */
function getMinimumReservationTimeError($element) {
    return $element.attr(ATTRIBUTES.DATA.MIN_REQUIRED_TIME_ERROR);
}

/**
 * Get maximum reservation duration error message from the element's data attribute values
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {string} maximum reservation duration error message
 */
function getMaximumReservationDurationError($element) {
    return $element.attr(ATTRIBUTES.DATA.MAX_REQUIRED_RESERVATION_DURATION_ERROR);
}

/**
 * Validates if the reservation time respects the minimum reservation time for the product
 * If valid, returns the original picker end date, isValid as true, and empty message
 * If invalid, returns the updated end date, isValid as false, and error message
 * @param {JQuery<HTMLElement>} $picker - date range picker jquery element
 * @param {string} minimumReservationTime - minimum reservation time required
 * @param {string} minimumReservationTimeError - minimum reservation time error message
 * @returns {Object} {endDate: date, isValid: boolean, message: string}
 */
function verifyMinReservationTime($picker, minimumReservationTime, minimumReservationTimeError) {
    // Variables
    var timeDifference;
    var result = {
        endDate: $picker.endDate,
        isValid: true,
        message: ''
    };

    timeDifference = moment($picker.endDate).diff(moment($picker.startDate), 'minutes');

    // If minimum reservation time is not respected, add it to the start date and show the user an error message
    if (minimumReservationTime && minimumReservationTime.length > 0 && timeDifference < minimumReservationTime) {
        result.endDate = moment($picker.startDate).add(minimumReservationTime, 'minutes');
        result.isValid = false;
        result.message = minimumReservationTimeError;
    }

    return result;
}

/**
 * Validates if the reservation duration respects the maximum reservation duration for the parking category
 * If valid, returns the original picker end date, isValid as true, and empty message
 * If invalid, returns the updated end date, isValid as false, and error message
 * @param {JQuery<HTMLElement>} $picker - date range picker jquery element
 * @param {string} maximumReservationDuration - minimum reservation time required
 * @param {string} maximumReservationDurationError - minimum reservation time error message
 * @returns {Object} {endDate: date, isValid: boolean, message: string}
 */
function verifyMaxReservationDuration($picker, maximumReservationDuration, maximumReservationDurationError) {
    // Variables
    var dateDifference;
    var startDate = $picker.startDate;
    var datePickerInput = $picker.element[0];
    var isDateRange = datePickerInput.dataset.isDaterange === 'true';
    var hasFrequency = datePickerInput.dataset.hasFrequency === 'true';
    var hasTime = datePickerInput.dataset.datepickerHasTime === 'true';
    var result = {
        endDate: $picker.endDate,
        isValid: true,
        message: ''
    };

    if (!isDateRange || !hasTime || hasFrequency) {
        return result;
    }

    // Set new start and end dates without hours and minutes to exclude their influence when executing diff()
    var diffStartDate = moment()
        .set('year', startDate.get('year'))
        .set('month', startDate.get('month'))
        .set('date', startDate.get('date'));
    var diffEndDate = moment()
        .set('year', $picker.endDate.get('year'))
        .set('month', $picker.endDate.get('month'))
        .set('date', $picker.endDate.get('date'));

    dateDifference = moment(diffEndDate).diff(moment(diffStartDate), 'days');

    var isMaxReservationDurationExceeded = dateDifference >= Number(maximumReservationDuration);

    if (
        isMaxReservationDurationExceeded &&
        dateDifference === Number(maximumReservationDuration) &&
        isDateRange &&
        hasTime
    ) {
        var startTime = moment(`${moment(startDate).hours()}:${moment(startDate).minutes()}`, 'hh:mm');
        var endTime = moment(`${moment($picker.endDate).hours()}:${moment($picker.endDate).minutes()}`, 'hh:mm');
        var timeIsBefore = startTime.isBefore(endTime);

        isMaxReservationDurationExceeded = timeIsBefore ? isMaxReservationDurationExceeded : timeIsBefore;
    }

    // If maximum reservation duration is not respected, add it to the start date and show the user an error message
    if (maximumReservationDuration && isMaxReservationDurationExceeded) {
        result.isValid = false;
        result.message = maximumReservationDurationError;
    }

    return result;
}

/**
 * Get date range picker linked calendars predefined value that is defined by the element's data attribute.
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {boolean} date range picker linked calendars predefined value
 */
function getLinkedCalendarsFlag($element) {
    return $element.attr(ATTRIBUTES.DATA.LINKED_CALENDARS) !== 'false';
}

/**
 * Get date range picker show dropdowns predefined value that is defined by the element's data attribute.
 * @param {JQuery<HTMLElement>} $element - date range picker jquery element
 * @returns {boolean} date range picker show dropdowns predefined value
 */
function getShowDropdowns($element) {
    return $element.attr(ATTRIBUTES.DATA.SHOW_DROPDOWNS) === 'true';
}

/**
 * Represents a date picker calendar(s).
 * @param {string | JQuery<HTMLElement>} selector - of the date picker
 * @param {Object} options - of the date picker
 * @returns {undefined} if there is an error
 */
function DatePicker(selector, options) {
    if (selector === null || selector === undefined || selector === '') {
        console.error(LOGS.ERROR.SELECTOR);
        return;
    }

    // 1. Set locale using the one selected in country selector
    setLocale();
    if (locale === undefined) {
        console.error(LOGS.ERROR.LOCALE);
        return;
    }

    // 2. Set $element
    this.$element = $(selector);
    if (this.$element.length === 0) {
        console.error(LOGS.ERROR.SELECTOR);
        return;
    }

    // 3. Is date range
    this.isDateRange = options !== null && options !== undefined && 'isRange' in options && options.isRange === true;
    this.hasTime = options !== null && options !== undefined && 'hasTime' in options && options.hasTime === true;

    // 5. Set date picker ID
    this.format = getFormat(this.$element, this.hasTime);

    // 6. Set date picker ID
    this.ID = getID(this.$element);

    // 7. Set start and end inputs
    this.$inputs = getInputs(this.ID, this.isDateRange);

    // 8. Set start and end spans
    this.$spans = getSpanElements(this.ID, this.isDateRange);

    // 9. Set daily min and max hours
    this.dailyHours = getDailyHours(this.$element);

    // 10. Dates that are defined on page load
    this.onLoadDates = getDates(this.$element);

    // 11. Set linked calendars flag
    this.isLinkedCalendars = getLinkedCalendarsFlag(this.$element);

    // 12. Set show dropdowns
    this.isShowDropdowns = getShowDropdowns(this.$element);

    // 13. Set datepicker instance
    this.dateRangePicker = getDateRangePicker(
        selector,
        this.isDateRange,
        this.$inputs,
        this.$spans,
        this.ID,
        this.hasTime,
        this.dailyHours,
        this.format,
        this.isLinkedCalendars,
        this.isShowDropdowns
    );

    // 14. Set redirect URL
    this.URL = getURL(this.$element);

    // 15. Set minimum reservation time
    this.minimumReservationTime = getMinimumReservationTime(this.$element);

    // 16. Set minimum reservation time
    this.maximumReservationDuration = getMaximumReservationDurationTime(this.$element);

    // 17. Set minimum reservation time error message
    this.minimumReservationTimeError = getMinimumReservationTimeError(this.$element);

    // 18. Set maximum reservation duration error message
    this.maximumReservationDurationError = getMaximumReservationDurationError(this.$element);
}

/**
 * Attach event listeners on calendar apply and hide date picker events.
 */
DatePicker.prototype.attachEventListeners = function () {
    const THIS = this;

    // 1. Event listeners related with $inputs
    if ('$inputs' in THIS && THIS.$inputs !== null) {
        // Update start and end inputs on hide date picker
        THIS.$element.on('hide.daterangepicker', function (e, picker) {
            // Constants
            const MIN_RESERVATION_TIME = verifyMinReservationTime(
                picker,
                THIS.minimumReservationTime,
                THIS.minimumReservationTimeError
            );

            // Variables
            var startDate = picker.startDate;
            var endDate = picker.endDate;

            // Update the picker's end date and show error message if minimum reservation time is not respected
            if (MIN_RESERVATION_TIME.isValid === false) {
                endDate = MIN_RESERVATION_TIME.endDate;
                picker.endDate = MIN_RESERVATION_TIME.endDate;
                THIS.$element.val(
                    startDate.format(moment.defaultFormatUtc) + ' - ' + endDate.format(moment.defaultFormatUtc)
                );

                $.alert(MIN_RESERVATION_TIME.message, false);
            }

            if ('start' in THIS.$inputs && THIS.$inputs.start !== null && THIS.$inputs.start !== undefined) {
                if (THIS.hasTime) {
                    THIS.$inputs.start.val(startDate.format(dateFormat));
                } else {
                    THIS.$inputs.start.val(startDate.format(dateFormatNoTime));
                }
            }

            if ('end' in THIS.$inputs && THIS.$inputs.end !== null && THIS.$inputs.end !== undefined) {
                if (THIS.hasTime) {
                    THIS.$inputs.end.val(endDate.format(dateFormat));
                } else {
                    THIS.$inputs.end.val(endDate.format(dateFormatNoTime));
                }
            }

            if ('full' in THIS.$inputs && THIS.$inputs.full !== null && THIS.$inputs.full !== undefined) {
                if (THIS.hasTime) {
                    if (THIS.isDateRange) {
                        THIS.$inputs.full.val(`${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`);
                    } else {
                        THIS.$inputs.full.val(startDate.format(dateFormat));
                    }
                } else if (THIS.isDateRange) {
                    THIS.$inputs.full.val(
                        `${startDate.format(dateFormatNoTime)} - ${endDate.format(dateFormatNoTime)}`
                    );
                } else {
                    THIS.$inputs.full.val(startDate.format(dateFormatNoTime));
                }
            }
        });

        /**
         * Attach an on click event listener to the card button.
         * This listener will trigger the click on the hidden input that
         * is right next to the button. This action will open the datepicker
         * to choose the dates.
         */
        if ('start' in THIS.$inputs && THIS.$inputs.start !== null && THIS.$inputs.start !== undefined) {
            THIS.$inputs.start.on('click', function (e) {
                e.preventDefault();
                THIS.$element.trigger('click');
            });
        }
        if ('end' in THIS.$inputs && THIS.$inputs.end !== null && THIS.$inputs.end !== undefined) {
            THIS.$inputs.end.on('click', function (e) {
                e.preventDefault();
                THIS.$element.trigger('click');
            });
        }
        if ('full' in THIS.$inputs && THIS.$inputs.full !== null && THIS.$inputs.full !== undefined) {
            THIS.$inputs.full.on('click', function (e) {
                e.preventDefault();
                THIS.$element.trigger('click');
            });
        }
    }

    // 2. Event listeners related with $spans
    if ('$spans' in THIS && THIS.$spans !== null) {
        // Update start and end spans on apply new date picker date
        THIS.$element.on('apply.daterangepicker', function (e, picker) {
            // Constants
            var MIN_RESERVATION_TIME = verifyMinReservationTime(
                picker,
                THIS.minimumReservationTime,
                THIS.minimumReservationTimeError
            );

            const MAX_RESERVATION_DURATION = verifyMaxReservationDuration(
                picker,
                Number(THIS.maximumReservationDuration),
                THIS.maximumReservationDurationError
            );

            // Variables
            var startDate = picker.startDate;
            var endDate = picker.endDate;

            // Update the picker's end date with the valid end date
            if (MIN_RESERVATION_TIME.isValid === false) {
                endDate = MIN_RESERVATION_TIME.endDate;
            }

            // If maximum reservation duration is not valid, update the picker with the valid end date and show error message
            if (MAX_RESERVATION_DURATION.isValid === false) {
                e.preventDefault();
                $.alert(MAX_RESERVATION_DURATION.message, false);
                return;
            }

            if ('start' in THIS.$spans && THIS.$spans.start !== null && THIS.$spans.start !== undefined) {
                THIS.$spans.start.find(SELECTORS.SHORT_START_DATE).html(startDate.format(THIS.format.short));
                THIS.$spans.start.find(SELECTORS.LONG_START_DATE).html(startDate.format(THIS.format.long));
            }
            if ('end' in THIS.$spans && THIS.$spans.end !== null && THIS.$spans.end !== undefined) {
                THIS.$spans.end.find(SELECTORS.SHORT_END_DATE).html(endDate.format(THIS.format.short));
                THIS.$spans.end.find(SELECTORS.LONG_END_DATE).html(endDate.format(THIS.format.long));
            }
        });

        /**
         * Attach an on 'outsideClick.daterangepicker' event listener when the user clicks outside the date range picker
         * This listener will get the 'cancel' button and will trigger the 'cancel' event
         * which sets the dates to the last dates applied.
         */
        THIS.$element.on('outsideClick.daterangepicker', function () {
            // Constants
            const OLD_DATES = {
                START:
                    'oldStartDate' in THIS.dateRangePicker && moment.isMoment(THIS.dateRangePicker.oldStartDate)
                        ? THIS.dateRangePicker.oldStartDate
                        : null,
                END:
                    'oldEndDate' in THIS.dateRangePicker && moment.isMoment(THIS.dateRangePicker.oldEndDate)
                        ? THIS.dateRangePicker.oldEndDate
                        : null
            };

            if (OLD_DATES.START !== null) {
                THIS.dateRangePicker.startDate = OLD_DATES.START;
            }

            if (OLD_DATES.END !== null) {
                THIS.dateRangePicker.endDate = OLD_DATES.END;
            }
        });

        /**
         * Attach an on 'show.daterangepicker' event listener when the calender is shown to the user.
         * This listener will validate if category is a date range, and if the end date is not valid.
         * If not valid, click on the start date elem so when the user clicks the calender, they start with the end date.
         */
        THIS.$element.on('show.daterangepicker', function (e, picker) {
            var $startDateElem;

            if (THIS.isDateRange && THIS.dateRangePicker.endDate.isValid() === false) {
                $startDateElem = picker.container.find('.drp-calendar.left .start-date');

                if ($startDateElem) {
                    $startDateElem.trigger('mousedown.daterangepicker');
                }
            }
        });

        /**
         * Attach an on click event listener to the card button.
         * This listener will trigger the click on the hidden input that
         * is right next to the button. This action will open the datepicker
         * to choose the dates.
         */
        if ('start' in THIS.$spans && THIS.$spans.start !== null && THIS.$spans.start !== undefined) {
            THIS.$spans.start.on('click', function (e) {
                e.preventDefault();
                THIS.$element.trigger('click');
            });
        }
        if ('end' in THIS.$spans && THIS.$spans.end !== null && THIS.$spans.end !== undefined) {
            THIS.$spans.end.on('click', function (e) {
                e.preventDefault();
                THIS.$element.trigger('click');
            });
        }
    } else {
        THIS.$element.on('apply.daterangepicker', function (e, picker) {
            const MAX_RESERVATION_DURATION = verifyMaxReservationDuration(
                picker,
                Number(THIS.maximumReservationDuration),
                THIS.maximumReservationDurationError
            );

            // If maximum reservation duration is not valid, update the picker with the valid end date and show error message
            if (MAX_RESERVATION_DURATION.isValid === false) {
                e.preventDefault();
                $.alert(MAX_RESERVATION_DURATION.message, false);
                return;
            }
        });
    }

    /**
     * 3. Event listeners related with daily hours
     * 3.1. Event listener on datepicker click/touch.
     * 3.2. Event listener on datepicker show.
     *      For both listeners Remove all hours that are not between the minimum and maximum range.
     *      Do this when the calendar is opened, and when any click is done inside the calendar.
     *      The hours are always re-rendered whenever the user performs a click inside the calendar.
     */
    if (THIS.hasTime && 'dailyHours' in THIS && THIS.dailyHours !== null) {
        var $calendar = THIS.dateRangePicker.container.find('.calendar-time');

        $('body').on('mousedown touchstart', $calendar, function () {
            var hours = [];

            if (THIS.isDateRange) {
                hours.push(THIS.dateRangePicker.container.find('.drp-calendar.left .hourselect').children());
                hours.push(THIS.dateRangePicker.container.find('.drp-calendar.right .hourselect').children());
            } else {
                hours.push(THIS.dateRangePicker.container.find('.drp-calendar .hourselect').children());
            }

            for (var i = 0; i < hours.length; i++) {
                var $hours = hours[i];

                if ('min' in THIS.dailyHours && THIS.dailyHours.min !== null && THIS.dailyHours.min !== undefined) {
                    $hours.filter(`:lt(${THIS.dailyHours.min})`).filter(':not([data-is-valid=true])').remove();
                }
                if ('max' in THIS.dailyHours && THIS.dailyHours.max !== null && THIS.dailyHours.max !== undefined) {
                    $hours.filter(`:gt(${THIS.dailyHours.max})`).filter(':not([data-is-valid=true])').remove();
                }

                $hours.attr('data-is-valid', true);
            }
        });

        THIS.$element.on('show.daterangepicker', function (e, picker) {
            var hours = [];

            if (THIS.isDateRange) {
                hours.push(picker.container.find('.drp-calendar.left .hourselect').children());
                hours.push(picker.container.find('.drp-calendar.right .hourselect').children());
            } else {
                hours.push(picker.container.find('.drp-calendar .hourselect').children());
            }

            for (var i = 0; i < hours.length; i++) {
                var $hours = hours[i];

                if ('min' in THIS.dailyHours && THIS.dailyHours.min !== null && THIS.dailyHours.min !== undefined) {
                    $hours.filter(`:lt(${THIS.dailyHours.min})`).filter(':not([data-is-valid=true])').remove();
                }
                if ('max' in THIS.dailyHours && THIS.dailyHours.max !== null && THIS.dailyHours.max !== undefined) {
                    $hours.filter(`:gt(${THIS.dailyHours.max})`).filter(':not([data-is-valid=true])').remove();
                }

                $hours.attr('data-is-valid', true);
            }
        });
    }

    // 4. Redirect on apply date picker
    if (
        'URL' in THIS &&
        THIS.URL !== null &&
        THIS.URL !== undefined &&
        THIS.URL !== 'null' &&
        THIS.URL !== 'undefined' &&
        THIS.URL !== ''
    ) {
        THIS.$element.on('apply.daterangepicker', function (e, picker) {
            // Constants
            const START_DATE = picker.startDate.format(moment.defaultFormat).split('+')[0] + 'Z';
            const END_DATE = picker.endDate.format(moment.defaultFormat).split('+')[0] + 'Z';
            const MIN_RESERVATION_TIME = verifyMinReservationTime(
                picker,
                THIS.minimumReservationTime,
                THIS.minimumReservationTimeError
            );

            const MAX_RESERVATION_DURATION = verifyMaxReservationDuration(
                picker,
                THIS.maximumReservationDuration,
                THIS.maximumReservationDurationError
            );

            // Variables
            var url = THIS.URL;
            var separator = url.indexOf('?') === -1 ? '?' : '&';
            var queryString;

            // If maximum reservation duration is not valid, update the picker with the valid end date and show error message
            if (MAX_RESERVATION_DURATION.isValid === false) {
                e.preventDefault();
                $.alert(MAX_RESERVATION_DURATION.message, false);
                return;
            }

            // If minimum reservation time is not valid, update the picker with the valid end date and show error message
            if (MIN_RESERVATION_TIME.isValid === false) {
                picker.endDate = MIN_RESERVATION_TIME.endDate;
                $.alert(MIN_RESERVATION_TIME.message, false);
                e.preventDefault();
                return;
            }

            // Set date query string parameters
            if (THIS.isDateRange) {
                url += `${separator}startDate=${encodeURIComponent(START_DATE)}&endDate=${encodeURIComponent(
                    END_DATE
                )}`;
            } else {
                url += `${separator}startDate=${encodeURIComponent(START_DATE)}`;
            }

            // Set order edit parameters
            separator = url.indexOf('?') === -1 ? '?' : '&';
            queryString = new URLSearchParams(window.location.search);

            if (queryString.has('editMode')) {
                url += `${separator}editMode=${encodeURIComponent(queryString.get('editMode'))}`;
            }

            if (queryString.has('orderNumber')) {
                url += `${separator}orderNumber=${encodeURIComponent(queryString.get('orderNumber'))}`;
            }

            if (queryString.has('productSummaryId')) {
                url += `${separator}productSummaryId=${encodeURIComponent(queryString.get('productSummaryId'))}`;
            }

            if (queryString.has('orderID')) {
                url += `${separator}orderID=${encodeURIComponent(queryString.get('orderID'))}`;
            }

            if (queryString.has('isApiOrder')) {
                url += `${separator}isApiOrder=${encodeURIComponent(queryString.get('isApiOrder'))}`;
            }

            if (queryString.has('quantity')) {
                url += `${separator}quantity=${encodeURIComponent(queryString.get('quantity'))}`;
            }

            if (queryString.has('subscriptionType')) {
                url += `${separator}subscriptionType=${encodeURIComponent(queryString.get('subscriptionType'))}`;
            }

            var currentUrl = new URL(window.location.href);

            if (currentUrl.searchParams.has(CONSTANTS.MODAL.PRODUCT_SEARCH.REDIRECT_PARAMETER_KEY)) {
                url += `${separator}${CONSTANTS.MODAL.PRODUCT_SEARCH.REDIRECT_PARAMETER_KEY}=${CONSTANTS.MODAL.PRODUCT_SEARCH.REDIRECT_PARAMETER_VALUE}`;
            }

            window.location.href = url;
        });
    }

    // 5. If is date range do the following:
    //      5.1. Update date range picker linkedCalendars property
    //      5.2. Add a class that exposes linkedCalendars property value
    if (THIS.isDateRange === true) {
        THIS.$element.on('show.daterangepicker hide.daterangepicker', function () {
            // Constants
            const IS_NOT_MOBILE = helpers.viewport.getViewportSize() !== CONSTANTS.BREAKPOINTS.MOBILE.ID;

            THIS.dateRangePicker.linkedCalendars = IS_NOT_MOBILE && THIS.isLinkedCalendars;

            if (IS_NOT_MOBILE) {
                THIS.dateRangePicker.container.addClass('comm-datepicker-linked-calendars');
            } else {
                THIS.dateRangePicker.container.removeClass('comm-datepicker-linked-calendars');
            }
        });
    }
};

/**
 * Update linked input values of a datepicker.
 * @param {string} _startDate - start date ISO string
 * @param {string} _endDate - end date ISO string
 */
DatePicker.prototype.updateInputValues = function (_startDate, _endDate) {
    // Constants
    const THIS = this;

    // Variables
    var startDate = moment(_startDate);
    var endDate = moment(_endDate);

    var orderRefinemetsDateFormat = 'DD/MMM/YY [-] LT';
    var orderRefinemetsDateFormatNoTime = 'DD/MMM/YY';

    if ('start' in THIS.$spans && THIS.$spans.start !== null && THIS.$spans.start !== undefined) {
        if (THIS.hasTime) {
            THIS.$spans.start.find(SELECTORS.LONG_START_DATE).html(startDate.format(orderRefinemetsDateFormat));
        } else {
            THIS.$spans.start.find(SELECTORS.LONG_START_DATE).html(startDate.format(orderRefinemetsDateFormatNoTime));
        }
    }

    if ('end' in THIS.$spans && THIS.$spans.end !== null && THIS.$spans.end !== undefined) {
        if (THIS.hasTime) {
            THIS.$spans.end.find(SELECTORS.LONG_END_DATE).html(endDate.format(orderRefinemetsDateFormat));
        } else {
            THIS.$spans.end.find(SELECTORS.LONG_END_DATE).html(endDate.format(orderRefinemetsDateFormatNoTime));
        }
    }

    if ('full' in THIS.$spans && THIS.$spans.full !== null && THIS.$spans.full !== undefined) {
        if (THIS.hasTime) {
            if (THIS.isDateRange) {
                THIS.$spans.full.html(
                    `${startDate.format(orderRefinemetsDateFormat)} - ${endDate.format(orderRefinemetsDateFormat)}`
                );
            } else {
                THIS.$spans.full.html(startDate.format(dateFormat));
            }
        } else if (THIS.isDateRange) {
            THIS.$spans.full.html(
                `${startDate.format(orderRefinemetsDateFormatNoTime)} - ${endDate.format(
                    orderRefinemetsDateFormatNoTime
                )}`
            );
        } else {
            THIS.$spans.full.html(startDate.format(orderRefinemetsDateFormatNoTime));
        }
    }
};

/**
 * Update linked input values of a datepicker.
 * @param {string} _startDate - start date ISO string
 * @param {string} _endDate - end date ISO string
 */
DatePicker.prototype.updateProductSearchInputValues = function (_startDate, _endDate) {
    // Constants
    const THIS = this;

    // Variables
    var startDate = moment(_startDate).utc();
    var endDate = moment(_endDate).utc();

    if ('start' in THIS.$spans && THIS.$spans.start !== null && THIS.$spans.start !== undefined) {
        if (THIS.hasTime) {
            THIS.$spans.start.find(SELECTORS.SHORT_START_DATE).html(startDate.format(THIS.format.short));
            THIS.$spans.start.find(SELECTORS.LONG_START_DATE).html(startDate.format(THIS.format.long));
        } else {
            THIS.$spans.start.find(SELECTORS.SHORT_START_DATE).html(startDate.format(THIS.format.short));
            THIS.$spans.start.find(SELECTORS.LONG_START_DATE).html(startDate.format(THIS.format.long));
        }
    }

    if ('end' in THIS.$spans && THIS.$spans.end !== null && THIS.$spans.end !== undefined) {
        if (THIS.hasTime) {
            THIS.$spans.end.find(SELECTORS.SHORT_END_DATE).html(endDate.format(THIS.format.short));
            THIS.$spans.end.find(SELECTORS.LONG_START_DATE).html(endDate.format(THIS.format.long));
        } else {
            THIS.$spans.end.find(SELECTORS.SHORT_END_DATE).html(endDate.format(THIS.format.short));
            THIS.$spans.end.find(SELECTORS.LONG_START_DATE).html(endDate.format(THIS.format.long));
        }
    }
};

module.exports = DatePicker;

/**
 * Collection of functions related with timer component
 */
var timer = {};
var intervalId;

// Module Includes
var moment = require('moment');

/**
 * Update Time UI
 * @param {string} value - update text value
 */
function setTimerUI(value) {
    $('.comm-timer-ui').each(function () {
        $(this).find('span').text(value);
    });
}

/**
 * Request to delete cart when checkout has expired
 * @returns {Promise} - ajax call result
 */
function deleteCart() {
    // Constants
    const cartDeleteUrl = $('input[name="cartDeleteUrl"]').val();

    if (cartDeleteUrl === 'undefined') {
        return Promise.resolve({ success: false }); // eslint-disable-line no-undef
    }

    $.spinner().start();
    setTimerUI('00:00');

    return $.ajax({
        url: cartDeleteUrl,
        method: 'GET',
        dataType: 'json',
        success: function () {
            // Dispatch delete cart event
            clearInterval(intervalId);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Handlers for when the expired countdown modal appears
 */
function expiredCountdown() {
    $('#ana-modal-countdown-expired-redirect').on('click', function () {
        // Constants
        const redirectUrl = $(this).data('url');
        if (redirectUrl !== 'undefined') {
            deleteCart().then((res) => {
                if (res.success) {
                    window.location.replace(redirectUrl);
                }
            });
        }
    });

    // Redirect if user clicks outside the modal
    $('#modalExpiredCountdown').on('hidden.bs.modal', function () {
        $('#modalExtendCountdown').data('bs.modal', null);
    });
}

/**
 * Handler for when the extend button on the modal is clicked
 */
function extendCart() {
    $('#ana-modal-countdown-extend').on('click', function () {
        const extendCartUrl = $(this).data('url');

        if (extendCartUrl === 'undefined') {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: extendCartUrl,
            method: 'GET',
            dataType: 'json',
            success: function (res) {
                if (res) {
                    if (res.success === false) {
                        $.alert(res.message, false);
                    } else {
                        window.localStorage.setItem('isModalShownOnce', false);
                        var timerEvent = new CustomEvent('comm:updateTimer', { detail: { value: res.timer } });
                        dispatchEvent(timerEvent);
                    }
                }
                window.localStorage.removeItem('isModalShownOnce');
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('#modalExtendCountdown').on('hidden.bs.modal', function () {
        $('#modalExtendCountdown').data('bs.modal', null);
    });

    $('#ana-modal-close-extend').on('click', function () {
        $('#modalExtendCountdown').modal('hide');
        let $timer = $('.comm-timer');
        $timer.data('showExtend', 'false');
    });
}

/**
 * Start the countdown for the customer to see time that rests
 */
const countdown = function () {
    let $timer = $('.comm-timer');
    var timerDateValue = $timer.val();
    var timerExtendModal = $timer.attr('data-timer-extend-modal');
    let timerDate = new Date(timerDateValue).getTime();

    let endTime = timerDate;

    if (!$timer || !timerDateValue || !timerDate) return;
    $timer.data('showExtend', isNaN(timerExtendModal) ? 'false' : 'true');

    intervalId = setInterval(function () {
        let remainingTime = (endTime - Date.now()) / 1000;

        let duration = moment.duration(remainingTime, 'seconds');
        let timerExtendModalSeconds = timerExtendModal * 60;

        // flag indicating the modal was already shown to user after timer has left 1 minute
        let isModalShownOnce = window.localStorage.getItem('isModalShownOnce');

        if (
            duration.as('seconds') <= timerExtendModalSeconds &&
            !isModalShownOnce &&
            !$('#modalExtendCountdown').hasClass('show') &&
            $timer.data('showExtend') === 'true'
        ) {
            // show modal
            $('div[role="dialog"]').modal('hide');
            $('#modalExtendCountdown').modal('show');
            window.localStorage.setItem('isModalShownOnce', true);
        }

        if (duration <= 0) {
            clearInterval(intervalId);
            window.localStorage.removeItem('isModalShownOnce');
            $('div[role="dialog"]').modal('hide');
            $('#modalExtendCountdown').modal('hide');
            $('#modalExpiredCountdown').modal('show');
        } else {
            // update timer UI
            setTimerUI(moment.utc(duration.as('milliseconds')).format('mm:ss'));
        }
    }, 1000);
};

/**
 * Collection of event listener definitions.
 */
const setEventListeners = {
    /**
     * Collection of focus window event listener definitions.
     */
    onWindowFocus: {
        /**
         * Execute all actions related when a timer expires when the page is not focused
         * and then is focused later. Actions to execute:
         *  - Delete cart and set every timer UI to 00:00
         */
        expiredTimerActions: function () {
            $(window).on('focus', function () {
                // Constants
                const $TIMER = $('.comm-timer');

                // Variables
                var expirationDateString;
                var expirationDate;
                var expirationTime;
                var remainingTime;
                var remainingSeconds;

                // Verify if timer HTML ELement exists
                if ($TIMER.length === 0) {
                    return;
                }

                // Get timer expiration date string
                expirationDateString = $TIMER.val();

                if (expirationDateString === undefined || expirationDateString === null) {
                    return;
                }

                // Convert timer expiration date string to date time
                expirationDate = new Date(expirationDateString);

                if (Object.prototype.toString.call(expirationDate) === '[object Date]' && isNaN(expirationDate)) {
                    return;
                }

                expirationTime = expirationDate.getTime();

                // Verify if timer is expired, and delete cart if it is
                remainingTime = (expirationTime - Date.now()) / 1000;
                remainingSeconds = moment.duration(remainingTime, 'seconds');

                if (remainingSeconds < 0) {
                    deleteCart();
                }
            });
        }
    }
};

/**
 * Initialize the event handlers
 */
const initEvents = function () {
    window.removeEventListener('comm:updateTimer', () => {}, true);
    window.addEventListener('comm:updateTimer', (e) => {
        if (e !== null && e.detail !== null && 'value' in e.detail) {
            // Variables
            let value = 'expirationDate' in e.detail.value ? e.detail.value.expirationDate : '';
            let extendReminder = 'timerExtendReminder' in e.detail.value ? e.detail.value.timerExtendReminder : 0;
            let $timer = $('.comm-timer');
            let timerDate = $timer.val();

            if (timerDate === '' || (timerDate && timerDate !== value && !isNaN(new Date(value)))) {
                clearInterval(intervalId);
                $timer.val(value);
                $timer.attr('data-timer-extend-modal', extendReminder);
                countdown();
            }
        }
        $('#modalExtendCountdown').modal('hide');
    });

    window.removeEventListener('comm:deleteTimer', () => {}, true);
    window.addEventListener('comm:deleteTimer', () => {
        let $timer = $('.comm-timer');
        if ($timer) {
            $timer.val(null);
        }

        deleteCart();
    });

    extendCart();
    expiredCountdown();
    countdown();

    setEventListeners.onWindowFocus.expiredTimerActions();
};

/**
 * Attach all store selector related event listeners.
 */
timer.setEventListeners = function () {
    initEvents(); // Initialize the events to store or remove the timer from session
};

module.exports = timer;

'use strict';

// Constants
var SELECTORS = {
    TEXTAREA: '.comm-form__textarea .form-control',

    ATTRIBUTE: {
        MAXLENGTH: 'maxlength'
    }
};

/**
 * Updates the character count for a specific textarea element.
 * @param  {jQuery<HTMLElement>} $textarea - jQuery object representing the textarea element.
 */
function updateCounter($textarea) {
    const MAXLENGTH = $textarea.data(SELECTORS.ATTRIBUTE.MAXLENGTH);
    const INPUTLENGTH = $textarea.val().length;

    $textarea.next().html(INPUTLENGTH + '/' + MAXLENGTH);
}

/**
 * Loads the initial state of the character counter for all textareas.
 */
function loadCounterState() {
    $(SELECTORS.TEXTAREA).each(function () {
        updateCounter($(this));
    });
}

/**
 * Attach an event listener to all textareas.
 * When the event listener triggers (e.g., input), it will update the character count.
 */
function updateCharCount() {
    loadCounterState();

    $('body').on('input', SELECTORS.TEXTAREA, function () {
        const $TEXTAREA = $(this);
        const MAXLENGTH = $TEXTAREA.data(SELECTORS.ATTRIBUTE.MAXLENGTH);
        const INPUTVALUE = $TEXTAREA.val();

        if (INPUTVALUE.length > MAXLENGTH) {
            $TEXTAREA.val(INPUTVALUE.substring(0, MAXLENGTH));
        }

        updateCounter($TEXTAREA);
    });
}

module.exports = {
    updateCharCount: updateCharCount
};

/**
 * @function inViewport
 * @description Verifies in a JQuery<HTMLElement> object is in viewport.
 * @returns {boolean} in viewport validation
 */
$.fn.inViewport = function () {
    var win = $(window);

    if (win) {
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };

        if (viewport) {
            viewport.right = viewport.left + win.width();
            viewport.bottom = viewport.top + win.height();

            var bounds = this.offset();

            if (bounds) {
                bounds.right = bounds.left + this.outerWidth();
                bounds.bottom = bounds.top + this.outerHeight();

                return !(
                    viewport.right < bounds.left ||
                    viewport.left > bounds.right ||
                    viewport.bottom < bounds.top ||
                    viewport.top > bounds.bottom
                );
            }
        }
    }

    return false;
};

/**
 * @function completlyInViewport
 * @description Verifies in a JQuery<HTMLElement> object is completly in viewport.
 * @returns {boolean} in viewport validation
 */
$.fn.completlyInViewport = function () {
    var win = $(window);

    if (win) {
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };

        if (viewport) {
            viewport.right = viewport.left + win.width();
            viewport.bottom = viewport.top + win.height();

            var bounds = this.offset();

            if (bounds) {
                bounds.right = bounds.left + this.outerWidth();
                bounds.bottom = bounds.top + this.outerHeight();

                return viewport.right > bounds.right && viewport.left <= bounds.left;
            }
        }
    }

    return false;
};

/**
 * @function moveInViewport
 * @description moves the element inside viewport
 */
$.fn.moveInViewport = function () {
    // if (this.hasClass('move-in-port')) {
    // values to move the element to fit inside the viewport
    const leftShiftValue = 5;
    const rightShiftValue = 3;

    var win = $(window);
    var elementOutsideWidth;
    var oldTranslateValue;
    var newTranslateValue;
    var newTranslatePercentage;

    // positions of the window
    var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft(),
        width: win.width()
    };

    // positions of the element
    var element = {
        top: this.offset().top,
        left: this.offset().left,
        width: this.outerWidth()
    };

    // calculate the right position value of viewport and element
    viewport.right = viewport.left + viewport.width;
    element.right = element.left + element.width;

    // moves to the left if the element on the right side is outside the viewport
    if (element.right > viewport.right) {
        elementOutsideWidth = Math.round(element.right - viewport.right);
        oldTranslateValue = this.css('transform').split(',')[4];
        newTranslateValue = elementOutsideWidth;

        if (oldTranslateValue < 0) {
            newTranslateValue = elementOutsideWidth - oldTranslateValue;
        }

        newTranslatePercentage = Math.round((newTranslateValue / element.width) * 100) + leftShiftValue;
        this.css('transform', 'translate(-' + newTranslatePercentage + '%, 0)');
    }

    // moves to the right if the element on the left side is outside the viewport
    if (element.left < viewport.left) {
        elementOutsideWidth = Math.round(element.left - viewport.left);
        oldTranslateValue = this.css('transform').split(',')[4];
        newTranslateValue = elementOutsideWidth;

        if (oldTranslateValue < 0) {
            newTranslateValue = elementOutsideWidth - oldTranslateValue;
        }

        newTranslatePercentage = Math.round((newTranslateValue / element.width) * 100) - rightShiftValue;
        this.css('transform', 'translate(-' + newTranslatePercentage + '%, 0)');
    }
    // }
};

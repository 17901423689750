'use strict';

/**
 * Collection of helper functions related with the client viewport.
 * @typedef ViewportHelpers
 * @property {function} getViewportSize get viewport size identifier as a string (mobile, tablet, desktop)
 */

/**
 * @type {ViewportHelpers}
 */
var helpers = {};

// Constants imports
var BREAKPOINT = require('../constants/breakpoints');

/**
 * Get viewport size identifier (mobile, tablet, desktop)
 * @returns {string} - viewport id
 */
helpers.getViewportSize = function () {
    // Constants
    const WIDTH = $(window).outerWidth();

    // Variables
    var result = null;

    if (WIDTH > BREAKPOINT.MOBILE.MIN_SIZE_PX && WIDTH < BREAKPOINT.MOBILE.MAX_SIZE_PX) {
        result = BREAKPOINT.MOBILE.ID;
    } else if (WIDTH > BREAKPOINT.TABLET.MIN_SIZE_PX && WIDTH < BREAKPOINT.TABLET.MAX_SIZE_PX) {
        result = BREAKPOINT.TABLET.ID;
    } else if (WIDTH > BREAKPOINT.DESKTOP.MIN_SIZE_PX) {
        result = BREAKPOINT.DESKTOP.ID;
    }

    return result;
};

module.exports = helpers;

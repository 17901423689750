/**
 * Collection of category card helper functions.
 */

var productSearch = {};

// Constants
var ATTRIBUTES = {
    DATA: {
        CATEGORY_ID: 'data-category-id',
        DATE_PICKER_ID: 'data-datepicker-id',
        IS_DATE_PICKER: 'data-is-datepicker',
        IS_DATE_RANGE: 'data-is-daterange',
        IS_SUBSCRIPTION_TYPE_SELECT: 'data-has-frequency',
        URL: 'data-url'
    }
};
var HTML_CLASS = {
    CATEGORY_INPUTS_PREFIX: 'comm-js-category-inputs-',
    DNONE: 'd-none'
};
var SELECTORS = {
    BUTTON_SUBMIT: 'button[type="submit"]',
    CATEGORY_SELECT: '.comm-form__category-select select',
    INPUT: '.ana-pd__asset__product-search .comm-form__input input:not(hidden)',
    INPUT_HIDDEN: 'input[hidden]',
    PRODUCT_SEARCH: '.ana-pd__asset__product-search',
    SUBSCRIPTION_TYPE_SELECT: '.comm-form__subscription-type-select select'
};

/**
 * Set selected category's submit button as visible.
 * @param {JQuery<HTMLSelectElement>} $select - product search category select
 */
function toogleButton($select) {
    // Constants
    const $SELECTED_OPTION = $select.find('option:selected');
    const CGID = $SELECTED_OPTION.attr(ATTRIBUTES.DATA.CATEGORY_ID);
    const $INPUT = $(`.${HTML_CLASS.CATEGORY_INPUTS_PREFIX}${CGID}`);
    const $OTHER_INPUTS = $(
        `[class*=${HTML_CLASS.CATEGORY_INPUTS_PREFIX}]:not(.${HTML_CLASS.CATEGORY_INPUTS_PREFIX}${CGID})`
    );

    $INPUT.removeClass(HTML_CLASS.DNONE);
    $OTHER_INPUTS.addClass(HTML_CLASS.DNONE);
}

/**
 * Attach an on page load event listener to the category select.
 */
productSearch.onPageLoad = function () {
    toogleButton($(SELECTORS.CATEGORY_SELECT));
};

/**
 * Attach an on click event listener to the card button.
 * This listener will trigger the click on the hidden input that
 * is right next to the button. This action will open the datepicker
 * to choose the dates.
 */
productSearch.onSubmit = function () {
    $('body').on('submit', SELECTORS.PRODUCT_SEARCH, function (e) {
        e.preventDefault();

        // Constants
        const $THIS = $(this);
        const $DATE_PICKER = $THIS.find(
            `[class*=${HTML_CLASS.CATEGORY_INPUTS_PREFIX}]:not(.${HTML_CLASS.DNONE}) input[${ATTRIBUTES.DATA.IS_DATE_PICKER}=true]`
        );
        const $SUBSCRIPTION_TYPE_SELECT = $THIS.find(SELECTORS.SUBSCRIPTION_TYPE_SELECT);
        const $URL = $THIS.find('select option:selected');
        const IS_DATE_RANGE = $DATE_PICKER.attr(ATTRIBUTES.DATA.IS_DATE_RANGE) === 'true';
        const IS_SUBSCRIPTION = $DATE_PICKER.attr(ATTRIBUTES.DATA.IS_SUBSCRIPTION_TYPE_SELECT) === 'true';
        const DATES = $DATE_PICKER.val();

        let START_DATE = '';
        let END_DATE = '';
        let SUBSCRIPTION_TYPE = '';

        if (DATES) {
            const SPLITTED_DATES = DATES.split(' - ');
            START_DATE = SPLITTED_DATES.length ? SPLITTED_DATES[0] : '';
            END_DATE = SPLITTED_DATES.length > 1 ? SPLITTED_DATES[1] : '';
            SUBSCRIPTION_TYPE = $SUBSCRIPTION_TYPE_SELECT.val();
        }

        // Variables
        var url = $URL.attr(ATTRIBUTES.DATA.URL);
        var separator = url.indexOf('?') === -1 ? '?' : '&';

        if (IS_DATE_RANGE) {
            url += `${separator}startDate=${encodeURIComponent(START_DATE)}&endDate=${encodeURIComponent(END_DATE)}`;
        } else if (DATES) {
            url += `${separator}startDate=${encodeURIComponent(START_DATE)}`;
        }

        if (IS_SUBSCRIPTION) {
            url += `&subscriptionType=${encodeURIComponent(SUBSCRIPTION_TYPE)}`;
        }

        window.location.href = url;
    });
};

/**
 * Attach an on change event listener to the category select.
 */
productSearch.onSelectorChange = function () {
    $('body').on('change', SELECTORS.CATEGORY_SELECT, function () {
        toogleButton($(this));
    });
};

module.exports = productSearch;

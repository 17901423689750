/**
 * Collection of constants related with modals.
 */
module.exports = {
    ACTIONS: {
        SHOW: 'show',
        HIDE: 'hide'
    },
    EVENT: {
        SHOW: 'show.bs.modal',
        SHOWN: 'shown.bs.modal',
        HIDE: 'hide.bs.modal',
        HIDDEN: 'hidden.bs.modal'
    },
    ID: {
        PRODUCT_SEARCH: 'comm-stores-product-search'
    },
    PRODUCT_SEARCH: {
        REDIRECT_PARAMETER_KEY: 'postATCRedirect',
        REDIRECT_PARAMETER_VALUE: '0',
        REDIRECT_CONTEXT_VALUE: 'postATCContext'
    }
};

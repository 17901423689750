/**
 * Collection of html elements lazyload methods
 */
var lazyLoad = {};

// Constants
var ATTRIBUTES = {
    DATA: {
        LAZYLOAD: 'data-lazyload',
        LOADED: 'data-loaded',
        OBSERVING: 'data-observing',
        SRC: 'data-src',
        SRC_SET: 'data-srcset',
        BACKGROUND_IMAGE: 'data-bgimage'
    },
    SRC: 'src',
    SRC_SET: 'srcset'
};
var DEBOUNCE_TIME = 50;
var LAZYLOAD_MODES = {
    INSTANT: 'instant',
    OBSERVER: 'observer'
};

// Variables
var debounceTimer = null;
var unloadedElements = [];

/**
 * Toggle the lazyload status classes.
 * From lazyload-unloaded to lazyloade-loaded.
 * @param {JQuery<HTMLElement>} $element - DOM JQuery HTML Element
 * @param {boolean} isLoaded - true if element is loaded
 */
function toogleLoadedState($element, isLoaded) {
    $element.attr(ATTRIBUTES.DATA.LOADED, isLoaded === true);
    $element.attr(ATTRIBUTES.DATA.OBSERVING, isLoaded === false);
}

/**
 * Set src attribute.
 * In the end the data-src attribute is removed to ensure that it doesn't load indefinitely.
 * @param {JQuery<HTMLElement>} $element - JQuery HTML Element to set the attribute source
 * @returns {void}
 */
function setSource($element) {
    const SOURCE = $element.attr(ATTRIBUTES.DATA.SRC);

    if (SOURCE) {
        $element.attr(ATTRIBUTES.SRC, SOURCE);
        $element.removeAttr(ATTRIBUTES.DATA.SRC);
        toogleLoadedState($element, true);
    }
}

/**
 * Set data-srcset attribute value to the srcset attribute.
 * @param {JQuery<HTMLElement>} $element - JQuery HTML Element to set the attribute source
 */
function setSourceSet($element) {
    const SOURCE_SET = $element.attr(ATTRIBUTES.DATA.SRC_SET);

    if (SOURCE_SET) {
        $element.attr(ATTRIBUTES.SRC_SET, SOURCE_SET);
        $element.removeAttr(ATTRIBUTES.DATA.SRC_SET);
        toogleLoadedState($element, true);
    }
}

/**
 * If the element is near the viewport, then its background image will be
 * setted with the value that is in the data-bg attribute.
 * @param {Element} $element - Wrapper that contains images to lazy load
 */
function setBackgroundImage($element) {
    const BACKGROUND_IMAGE = $element.attr(ATTRIBUTES.DATA.BACKGROUND_IMAGE);

    if (BACKGROUND_IMAGE) {
        $element.css('background-image', `url(${BACKGROUND_IMAGE})`);
        $element.removeAttr(ATTRIBUTES.DATA.BACKGROUND_IMAGE);
        toogleLoadedState($element, true);
    }
}

/**
 * Lazy load debouncer.
 */
function debounce() {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(function () {
        for (const $UNLOADED_ELEMENT of unloadedElements) {
            setSource($UNLOADED_ELEMENT);
            setSourceSet($UNLOADED_ELEMENT);
            setBackgroundImage($UNLOADED_ELEMENT);
        }
        unloadedElements = [];
    }, DEBOUNCE_TIME);
}

/**
 * Observe elements in viewport to lazy load using intersection observer.
 */
function observe() {
    const OPTIONS = {
        root: null,
        rootMargin: '750px 2000px 750px 2000px',
        threshold: 0.5
    };

    var $elements = $(
        `[${ATTRIBUTES.DATA.LAZYLOAD}=${LAZYLOAD_MODES.OBSERVER}]:not([${ATTRIBUTES.DATA.LOADED}=true][${ATTRIBUTES.DATA.OBSERVING}=true])`
    );

    if ($elements.length) {
        const OBSERVER = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    // Unobserve to avoid sending duplicated data
                    OBSERVER.unobserve(entry.target);

                    const $TARGET = $(entry.target);

                    // Add the observed element to the array of elements to load
                    if ($TARGET.length) {
                        unloadedElements.push($TARGET);
                    }

                    // Load element src and srcset attributes (using a debouncer)
                    debounce();
                }
            });
        }, OPTIONS);

        // Attach an observer to every element to lazy load
        $elements.each(function () {
            $(this).attr(ATTRIBUTES.DATA.OBSERVING, true);
            OBSERVER.observe($(this).get(0), {
                attributes: true,
                childList: false,
                subtree: false
            });
        });
    }
}

/**
 * Instantly load all elements that have the attribute lazyload="instant".
 */
function instantLoad() {
    // Constants
    const $ELEMENTS = $(
        `[${ATTRIBUTES.DATA.LAZYLOAD}=${LAZYLOAD_MODES.INSTANT}]:not([${ATTRIBUTES.DATA.LOADED}=true])`
    );

    // Variables
    var $unloadedELement;

    $ELEMENTS.each(function () {
        $unloadedELement = $(this);
        setSource($unloadedELement);
        setSourceSet($unloadedELement);
        setBackgroundImage($unloadedELement);
    });
}

/**
 * Attach all event listeners to body.
 */
lazyLoad.setEventListeners = function () {
    $('body').on('lazyLoad:observe', function () {
        observe();
    });

    $('body').on('lazyLoad:instant', function () {
        instantLoad();
    });
};

/**
 * Trigger all event listeners.
 */
lazyLoad.triggerEventListeners = function () {
    $('body').trigger('lazyLoad:observe');
    $('body').trigger('lazyLoad:instant');
};

module.exports = lazyLoad;

'use strict';

// Constants
var SELECTORS = {
    CHILDREN:
        '.form-group.required.comm-form__input > input, .form-group.required.comm-form__textarea > textarea, .form-group.required.comm-form__select > select'
};

/**
 * Clear the forms on page change.
 */
function setEventListeners() {
    $(window).on('beforeunload', function () {
        $('form[data-validate-required-fields=true]').each(function () {
            $(this).find(SELECTORS.CHILDREN).val('');
        });
    });
}

module.exports = {
    setEventListeners: setEventListeners
};

/**
 * Object with all viewport size constants.
 * @typedef ViewportBreakpoints
 * @property {Object} MOBILE Mobile viewport contants
 * @property {string} MOBILE.ID Mobile viewport global identifier
 * @property {number} MOBILE.MIN_SIZE_PX Mobile viewport minimum size in pixels
 * @property {number} MOBILE.MAX_SIZE_PX Mobile viewport maximum size in pixels
 * @property {Object} TABLET Tablet viewport contants
 * @property {string} TABLET.ID Tablet viewport global identifier
 * @property {number} TABLET.MIN_SIZE_PX Tablet viewport minimum size in pixels
 * @property {number} TABLET.MAX_SIZE_PX Tablet viewport maximum size in pixels
 * @property {Object} DESKTOP Desktop viewport contants
 * @property {string} DESKTOP.ID Desktop viewport global identifier
 * @property {number} DESKTOP.MIN_SIZE_PX Desktop viewport minimum size in pixels
 * @property {number} DESKTOP.MAX_SIZE_PX Desktop viewport maximum size in pixels
 */

/**
 * @type {ViewportBreakpoints}
 */
module.exports = {
    MOBILE: {
        ID: 'mobile',
        MIN_SIZE_PX: 0,
        MAX_SIZE_PX: 767
    },
    TABLET: {
        ID: 'tablet',
        MIN_SIZE_PX: 768,
        MAX_SIZE_PX: 1023
    },
    DESKTOP: {
        ID: 'desktop',
        MIN_SIZE_PX: 1024
    }
};

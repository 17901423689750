/**
 * Collection of constants related with products.
 */
module.exports = {
    SUBSCRIPTIONS: {
        RENEWAL_PARAMETER_KEY: 'postATCRedirect',
        REDIRECT_PARAMETER_VALUE: true
    },
    REPLACEMENT: {
        TYPE: {
            ORDER: 0,
            ORDER_ITEM: 1
        },
        PARAMETER_KEY: {
            EDIT_MODE: 'editMode',
            ORDER_NUMBER: 'orderNumber',
            PRODUCT_SUMMARY_ID: 'productSummaryId',
            ORDER_ID: 'orderID',
            IS_API_ORDER: 'isApiOrder'
        },
        REDIRECT_KEY: {
            ORDER_ITEM: '0'
        },
        REDIRECT_CONTEXT: {
            ORDER_ITEM: 'editOrderItem'
        }
    },
    VALIDATION: {
        PARAMETER_KEY: {
            CSRF: 'csrf_token'
        }
    }
};

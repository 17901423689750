'use strict';

var focusHelper = require('base/components/focus');

/**
 * Renders a panel that will track the users consenting to accepting site tracking policy
 */
function showConsentPanel() {
    var trackingConsentData = $('.tracking-consent');
    if (!trackingConsentData.data('caonline')) {
        return;
    }

    var urlContent = trackingConsentData.data('url');
    var urlAccept = trackingConsentData.data('accept');
    var urlReject = trackingConsentData.data('reject');
    var textYes = trackingConsentData.data('accepttext');
    var textNo = trackingConsentData.data('rejecttext');
    var tokenName = trackingConsentData.data('tokenname');
    var token = trackingConsentData.data('token');

    var htmlString =
        '<div class="comm-panel__bottom" id="consent-tracking">' +
        '<div class="comm-panel__bottom--body"></div>' +
        '<div class="comm-panel__bottom--button-wrapper">' +
        '<button class="affirm ana-button--gradient--dark-blue--centered" data-url="' +
        urlAccept +
        '">' +
        textYes +
        '</button>' +
        '<button class="decline ana-button--grey--centered" data-url="' +
        urlReject +
        '" >' +
        textNo +
        '</button>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('.comm-panel__bottom--body').html(response);
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('#consent-tracking button').click(function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var data = {};
        data[tokenName] = token;
        $.ajax({
            url: url,
            type: 'post',
            data: data,
            dataType: 'json',
            success: function (response) {
                // Only hide modal if the operation is successful - don't want to give a false impression
                if (response.success) {
                    $('#consent-tracking').remove();
                }
            },
            error: function (err) {
                // Expected error response is for CSRF failure, which will include a redirect to CSRF-Fail
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            }
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentPanel();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentPanel();
        });
    }

    $('body').on('keydown', '#consent-tracking', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.decline',
            nextToLastElementSelector: '.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};

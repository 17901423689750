'use strict';

// Constants
var SELECTORS = {
    CLASS: {
        BUTTON: '.comm-form__password svg',
        FIELD: '.comm-form__password svg + input'
    },
    ATTRIBUTE: {
        TYPE: 'type',
        VALUE_PASSWORD: 'password',
        VALUE_TEXT: 'text'
    }
};

/**
 * Attach an event listener to an element.
 * When the event listener triggers, it will toogle the password.
 */
function togglePasswordType() {
    $('body').on('click', SELECTORS.CLASS.BUTTON, function () {
        // Constants
        const $PASSWORD_FIELD = $(SELECTORS.CLASS.FIELD);
        const $CURRENT_TYPE = $PASSWORD_FIELD.attr(SELECTORS.ATTRIBUTE.TYPE);
        const ATTRIBUTE_VALUE =
            $CURRENT_TYPE === SELECTORS.ATTRIBUTE.VALUE_PASSWORD
                ? SELECTORS.ATTRIBUTE.VALUE_TEXT
                : SELECTORS.ATTRIBUTE.VALUE_PASSWORD;

        $PASSWORD_FIELD.attr(SELECTORS.ATTRIBUTE.TYPE, ATTRIBUTE_VALUE);
    });
}

module.exports = {
    togglePasswordType: togglePasswordType
};

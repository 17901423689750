/**
 * Collection of functions related with store selector compnent
 */
var storeSelector = {};

// Constants
var SELECTOR = {
    DROPDOWN: '.ana-dropdown__airport-selector',
    DROPDOWN_TOGGLE: '.ana-dropdown__airport-selector .dropdown-toggle',
    DROPDOWN_MENU: '.ana-dropdown__airport-selector .dropdown-menu',
    DROPDOWN_ITEM: '.ana-dropdown__airport-selector .dropdown-menu .dropdown-item'
};
var ATTRIBUTE = {
    DATA: {
        URL: 'data-url'
    }
};

/**
 * Attach an event listener to listen for dropdown item store clicks.
 * The context store is changed when a store is selected.
 */
function onStoreClick() {
    $('body').on('click', SELECTOR.DROPDOWN_ITEM, function (e) {
        e.preventDefault();

        // Constants
        const $this = $(this);
        const URL = $this.attr(ATTRIBUTE.DATA.URL);

        if (URL) {
            $.spinner().start();
            $.ajax({
                url: URL,
                type: 'POST',
                dataType: 'json',
                success: function (response) {
                    if (
                        response &&
                        'success' in response &&
                        response.success &&
                        'redirectURL' in response &&
                        typeof response.redirectURL === 'string'
                    ) {
                        location.href = response.redirectURL;
                    } else {
                        $.spinner().stop();
                    }
                },
                error: function (error) {
                    console.error(error);
                    $.spinner().stop();
                }
            });
        }
    });
}

/**
 * Attach all store selector related event listeners.
 */
storeSelector.setEventListeners = function () {
    onStoreClick();
};

module.exports = storeSelector;

/**
 * Collection of dropdown selector related scripts.
 */
var dropdown = {};

// Constants
var SELECTOR = {
    CLASS: {
        DROPDOWN_MENU: 'dropdown-menu',
        SHOW: 'show'
    }
};

/**
 * Attach a mutation observer to all dropdown selector elements, with the following objectives:
 *      - Relocate dropdown menu if it is outside viewport.
 */
dropdown.onShow = function () {
    // Constants
    const $DROPDOWN_MENU = $(`.${SELECTOR.CLASS.DROPDOWN_MENU}`);

    // 1. Define mutation observer
    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            var $target = $(mutation.target);
            var attributeValue = $target.prop(mutation.attributeName);

            if (attributeValue.indexOf(SELECTOR.CLASS.SHOW) !== -1) {
                $target.moveInViewport();
            }
        });
    });

    // 2. Attach mutation observer
    $DROPDOWN_MENU.each(function () {
        observer.observe(this, {
            attributes: true,
            attributeFilter: ['class']
        });
    });
};

module.exports = dropdown;

'use strict';

// Constants
var SELECTORS = {
    INPUT: '.comm-form__input',
    PASSWORD: '.comm-form__password',
    TEXTAREA: '.comm-form__textarea',
    ALL: '.comm-form__input, .comm-form__password, .comm-form__textarea',
    CHILDREN: 'input, textarea'
};

var CLASS = {
    ACTIVE: 'active'
};

/**
 * Apply the 'active' class based on input value.
 * @param {jQuery<HTMLElement>} $element - The jQuery object representing the element to apply the class to.
 */
function setActiveClass($element) {
    const VALUE = $element.find($(SELECTORS.CHILDREN)).val();

    if (VALUE !== '') {
        $element.addClass(CLASS.ACTIVE);
    }
}

/**
 * Remove the 'active' class based on input value.
 * @param {jQuery<HTMLElement>} $element - The jQuery object representing the element to remove the class to.
 */
function removeActiveClass($element) {
    const VALUE = $element.find($(SELECTORS.CHILDREN)).val();

    if (VALUE === '') {
        $element.removeClass(CLASS.ACTIVE);
    }
}

/**
 * Apply the 'active' class on fields already filled on page load.
 */
function loadInputState() {
    const $INPUTS = $('body').find(SELECTORS.ALL);

    $INPUTS.each(function () {
        setActiveClass($(this));
    });
}

/**
 * Handle events on input elements.
 */
function setInputEventListeners() {
    $('body')
        .on('input', SELECTORS.ALL, function () {
            setActiveClass($(this));
        })
        .on('focus', SELECTORS.ALL, function () {
            $(this).addClass(CLASS.ACTIVE);
        })
        .on('blur', SELECTORS.ALL, function () {
            removeActiveClass($(this));
        });
}

module.exports = {
    loadInputState: loadInputState,
    setInputEventListeners: setInputEventListeners
};

'use strict';

// Script Includes
var cart = require('../cart/cart');

// Variables
var updateMiniCart = true;

/**
 * Changes:
 *      - Add documentation;
 *      - Open minicart even if it doesn't has any product line item.
 * @override app_storefront_base
 */
module.exports = function () {
    // Initialize all global cart functionalities
    cart();

    /**
     * Attach event listener to update minicart total product line items count.
     * @event count:update
     */
    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal) && count.quantityTotal > 0) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });

            // Display the count indicator
            $('.minicart-quantity').removeClass('d-none');

            // Search Page Only: Update proceed to checkout button
            $('body').trigger('search:updateCheckoutButton', count.quantityTotal);
        }
    });

    /**
     * Attach event listener to open minicart on mouse over, focus and click.
     * @event mouseenter
     * @event focusin
     * @event click
     */
    $('.minicart').on('mouseenter focusin click', function (e) {
        const isTouchEvent = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

        // If the target is not a button and it's a touch device, prevent it from going to the cart
        if (!$(e.target).is('[role="button"]') && !$(e.target).parent().is('button') && isTouchEvent) {
            e.preventDefault();
        }

        var url = $('.minicart').data('action-url');

        if ($('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');

            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }

        if ($('.minicart .popover.popover-bottom').hasClass('show') && !$(e.target).closest('.minicart .popover').length) {
            $('.minicart .popover.popover-bottom').removeClass('show');
        }
    });

    /**
     * Attach event listener to close minicart when touching or clicking the body.
     * @event touchstart
     * @event click
     */
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });

    /**
     * Attach event listener to close minicart when the cursor is not on the cart popover HTML element.
     * @event mouseleave
     * @event focusout
     */
    $('.ana-header__col-r').on('mouseleave focusout', function (event) {
        if (
            (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')
        ) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });

    /**
     * Attach event listener to open minicart on mouse over, focus and touch start.
     * @event mouseenter
     * @event focusin
     * @event touchstart
     */
    $('.ana-dropdown').on('mouseenter focusin touchstart', function (event) {
        if (
            (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')
        ) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });

    /**
     * Attach event listener to reload page if we are currently in cart page, and there are bonus product line
     * items in cart. This is only executed if there is a change to the minicart quantity element.
     * @event change
     */
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    /**
     * Attach event listener to change updateMinicart value to true.
     * @event product:afterAddToCart
     */
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });

    /**
     * Attach event listener to change updateMinicart value to true.
     * @event product:afterAddToCart
     */
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};

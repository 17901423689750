'use strict';

// Constant Includes
var CONSTANTS = require('../constants/spinner');

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner. Pass body to block the whole page.
 * @override app_storefront_base
 */
function addSpinner($target, type) {
    var $veil = $('<div class="veil"><div class="underlay"></div></div>');

    if (type === CONSTANTS.DEFAULT) {
        $veil.append('<div class="spinner"></div></div>');
    }

    if (type === CONSTANTS.PRODUCT_TILE) {
        $veil.append(
            '<div class="tile-loading">' +
                '<div class="img-container-loading">' +
                '<div class="img-loading">' +
                '</div>' +
                '</div>' +
                '<div class="content-loading">' +
                '<div class="stripe stripe-100">' +
                '</div>' +
                '<div class="stripe stripe-80">' +
                '</div>' +
                '<div class="stripe stripe-60">' +
                '</div>' +
                '<div class="stripe stripe-40">' +
                '</div>' +
                '<div class="stripe stripe-20">' +
                '</div>' +
                '</div>' +
                '</div>'
        );
    }

    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({ width: $target.width(), height: $target.height() });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled');
        }
        if ($target.get(0).tagName === 'BODY') {
            $veil.find('.spinner').css('position', 'fixed');
        }
    }
    $veil.click(function (e) {
        e.stopPropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 * @override app_storefront_base
 */
function removeSpinner($veil) {
    if ($veil.parent().hasClass('veiled')) {
        $veil.parent().css('position', '');
        $veil.parent().removeClass('veiled');
    }
    $veil.off('click');
    $veil.remove();
}

/**
 * Spinner - Element level
 * Execute or stop a spinner in an element.
 * @param {number} _type - loading type
 * @returns {Fn} JQuery function
 */
$.fn.spinner = function (_type) {
    var $veil;
    var $element = $(this);
    var type = _type || CONSTANTS.DEFAULT;
    var Fn = function () {
        this.start = function () {
            if ($element.length) {
                addSpinner($element, type);
            }
        };
        this.stop = function () {
            if ($element.length) {
                $veil = $element.find('> .veil');
                removeSpinner($veil);
            } else {
                $veil = $('.veil');
                removeSpinner($veil);
            }
        };
    };
    return new Fn();
};

/**
 * Spinner - Page level
 * Execute or stop a spinner to page body.
 * @returns {Fn} JQuery function
 */
$.spinner = function () {
    var Fn = function () {
        this.start = function () {
            addSpinner($('body'), CONSTANTS.DEFAULT);
        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};

'use strict';

// Constants
var SELECTORS = {
    CLASS: {
        SHOW: 'ana-accordion--show',
        COLLAPSE: '.ana-accordion__body.collapse'
    }
};
var EVENTS = {
    SHOW: 'show.bs.collapse',
    HIDE: 'hide.bs.collapse'
};

/**
 * Set accordion "show" and "hide" event listeners.
 */
function setEventListeners() {
    // 1. Event listener - On show
    $(SELECTORS.CLASS.COLLAPSE).on(EVENTS.SHOW, function () {
        $(this).parent().addClass(SELECTORS.CLASS.SHOW);
    });

    // 2. Event listener - On hide
    $(SELECTORS.CLASS.COLLAPSE).on(EVENTS.HIDE, function () {
        $(this).parent().removeClass(SELECTORS.CLASS.SHOW);
    });
}

module.exports = {
    setEventListeners: setEventListeners
};

'use strict';

// Constants
var SELECTORS = {
    ATTRIBUTE: {
        CLICK_OUTSIDE: '[data-click-outside]'
    },
    CLASS: {
        SHOW: 'show'
    },
    EVENT: {
        CLICK: 'click'
    }
};

/**
 * Collapses element on click outside
 */
function collapseOnClickOutside() {
    $('body').on(SELECTORS.EVENT.CLICK, function (e) {
        if (
            $(SELECTORS.ATTRIBUTE.CLICK_OUTSIDE).hasClass(SELECTORS.CLASS.SHOW) &&
            $(e.target).closest(SELECTORS.ATTRIBUTE.CLICK_OUTSIDE).length === 0
        ) {
            $(SELECTORS.ATTRIBUTE.CLICK_OUTSIDE).collapse('hide');
        }
    });
}

module.exports = {
    collapseOnClickOutside: collapseOnClickOutside
};

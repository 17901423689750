/**
 * Collection of all datepicker component related scripts
 */
var select = {};

// Model Includes
var SelectModel = require('../model/SelectModel');

// Constants
var ATTRIBUTES = {
    DATA: {
        ID: 'data-select-id',
        IS_LOADED: 'data-is-select-loaded',
        IS_SELECT: 'data-is-select',
        OPTIONS: 'data-select-options'
    }
};

/**
 * Initialize all select components.
 * @param {null | undefined | JQuery<HTMLElement>} _$selects - initialize selects. If non is provider, then it will initialize all date pickers.
 * @param {null | undefined | Array} availabilityData - product availability data
 */
select.init = function (_$selects, availabilityData) {
    // Constants
    var $selects = $(`[${ATTRIBUTES.DATA.IS_SELECT}=true]:not([${ATTRIBUTES.DATA.IS_LOADED}=true])`);

    // Get custom selects to initialize
    if (_$selects !== null && _$selects !== undefined && _$selects.length) {
        $selects = _$selects;
    }

    // Initialize window global variable that stores all custom selects active in page
    if (!('commCustomSelects' in window) || !Array.isArray(window.commCustomSelects)) {
        window.commCustomSelects = [];
    }

    $selects.each(function (index, element) {
        // Constants
        const $ELEMENT = $(element);
        const ID = $ELEMENT.attr(ATTRIBUTES.DATA.ID);
        const OPTIONS = $ELEMENT.attr(ATTRIBUTES.DATA.OPTIONS);

        // Create a new DatePicker instance
        const model = new SelectModel(
            $ELEMENT,
            ID,
            OPTIONS !== null && OPTIONS !== undefined && OPTIONS !== 'null' && OPTIONS !== 'undefined'
                ? OPTIONS
                : undefined
        );

        model.setSelect2();
        model.setSelectOptions(availabilityData);
        model.setEventListeners();
        window.commCustomSelects.push(model);
        $ELEMENT.attr(ATTRIBUTES.DATA.IS_LOADED, 'true');
    });
};

module.exports = select;

// Constants
var PARAMETER = {
    DEFAULT: {
        MAX_SHOW_OPTIONS_LIMIT: 10,
        OPTIONS: { width: 'element' }
    }
};
var HTML_CLASS = {
    SELECT2: 'select2',
    QTY_SELECTOR: 'quantity-select',
    ARROW: 'comm-icon--select-arrow',
    ROTATE_180: 'comm-animation--rotate--r180'
};
var EVENT = {
    ARROW_ROTATION: 'arrowRotation'
};

/**
 * Creates a customizable select element using select2 library.
 * @param {JQuery<HTMLElement> | string} $element - select element to use on select2 element build
 * @param {string} id - Object instance identifier. Useful to get object instance after page load.
 * @param {Object=} options - based on https://select2.org/configuration/options-api
 */
function SelectModel($element, id, options = PARAMETER.DEFAULT.OPTIONS) {
    this.$element = $($element);
    this.id = id;
    this.$select2 = null;

    try {
        this.options = typeof options === 'string' ? JSON.parse(options) : options;
    } catch (error) {
        console.error('Bad select2 configuration');
    }
}

/**
 * Initialize select 2 based on $element.
 */
SelectModel.prototype.setSelect2 = function () {
    if (this.$element.length) {
        $(this.$element).select2(this.options);
    }

    this.$select2 = this.$element.siblings(`.${HTML_CLASS.SELECT2}`);
};

/**
 * Remove select 2 from current page.
 */
SelectModel.prototype.destroySelect2 = function () {
    this.$element.select2('destroy');
};

/**
 * Object with a collection of methods that attach event listeners to the select element.
 * @param {string=} event - event associated to the listener to attach
 */
SelectModel.prototype.setEventListeners = function (event) {
    // Constants
    const $ELEMENT = this.$element;

    /**
     * Rotate select arrow on open/close select2 event listener.
     */
    if (event === undefined || event === EVENT.ARROW_ROTATION) {
        $ELEMENT.on('select2:open', function () {
            const $ARROW = $ELEMENT.siblings(`.${HTML_CLASS.ARROW}`);

            if ($ARROW.length) {
                $ARROW.addClass(HTML_CLASS.ROTATE_180);
            }
        });
        $ELEMENT.on('select2:close', function () {
            const $ARROW = $ELEMENT.siblings(`.${HTML_CLASS.ARROW}`);

            if ($ARROW.length) {
                $ARROW.removeClass(HTML_CLASS.ROTATE_180);
            }
        });
    }
};

/**
 * Append option to qty select
 * @param {JQuery<HTMLElement>} $selector - jQuery selector element
 * @param {number} value - option's value and text
 * @param {boolean} isSelectedOption - flag indication option is marked as selected
 */
SelectModel.prototype.appendOption = function ($selector, value, isSelectedOption) {
    $selector.val(null).append(new Option(value, value, isSelectedOption, isSelectedOption));
};

/**
 * Set dynamically options in qty selectors based on availability data
 * @param {Array} data - array with products availability data
 */
SelectModel.prototype.setSelectOptions = function (data) {
    const $ELEMENT = this.$element;

    if (data && $ELEMENT.hasClass(HTML_CLASS.QTY_SELECTOR)) {
        $ELEMENT.empty();

        const matchingData = data.find(function (item) {
            return item.productId === $ELEMENT.data('pid');
        });

        if (!matchingData || !matchingData.available) {
            this.appendOption($ELEMENT, 1, true);
            return;
        }

        for (let i = 1; i <= PARAMETER.DEFAULT.MAX_SHOW_OPTIONS_LIMIT; i++) {
            this.appendOption($ELEMENT, i, i === 1);
        }

        $ELEMENT.attr('data-availability', matchingData.available);
    }
};

module.exports = SelectModel;

// JQuery setup
window.jQuery = window.$ = require('jquery');
require('design/jquery/viewport');
require('./jquery/alert');

// Plugins imports
require('design/vendors/daterangepicker');
require('select2');

// Constants
var processInclude = require('base/util');

$(document).ready(function () {
    // app_storefront_base cartridge includes
    processInclude(require('base/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/footer'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/toolTip'));

    // overrides from app_storefront_base cartridge
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/clientSideValidation'));

    // custom cartridges includes
    processInclude(require('design/components/accordion'));
    processInclude(require('design/components/clickOutside'));
    processInclude(require('design/components/datepicker'));
    processInclude(require('design/components/dropdown'));
    processInclude(require('design/components/header'));
    processInclude(require('design/components/password'));
    processInclude(require('design/components/charCounter'));
    processInclude(require('design/components/select'));
    processInclude(require('design/components/moveLabel'));
    processInclude(require('design/utilities/validateForm'));
    processInclude(require('design/utilities/clearForm'));
    processInclude(require('design/utilities/lazyLoad'));
    processInclude(require('./components/storeSelector'));
    processInclude(require('./components/timer'));
    processInclude(require('./modals/index'));

    // global page designer experience includes
    // only valid if there is an advantage to include files here, import directly in ISML otherwise
    processInclude(require('./experience/commerceAssets/productSearch'));
});

// app_storefront_base cartridge includes
require('base/thirdParty/bootstrap');

// overrides from app_storefront_base cartridge
require('design/components/spinner');

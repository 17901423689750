/**
 * Collection of all alert related functions.
 */

// Constants
var CSS_CLASS = {
    SHOWN: 'ana-alert__shown'
};
var SELECTOR = {
    ALERT: '.ana-alert',
    CONTENT: '.ana-alert__content'
};
var ATTRIBUTE = {
    DATA: {
        TYPE: 'data-type'
    }
};
var TYPE = {
    SUCCESS: 'success',
    ERROR: 'error'
};
var DURATION_MS = 5000;

// Variables
var isActive = false;
var timeout = null;

/**
 * Show alert with a message and a type (success or error).
 * @param {string} message - of the alert
 * @param {boolean} success - true if a success alert, false otherwise. default value is true
 * @returns {undefined} if there is an error
 */
function show(message, success = true) {
    // Constants
    const $ALERT = $(SELECTOR.ALERT);
    const $CONTENT = $(SELECTOR.CONTENT);

    // 1. Parameter validations
    if (message === null || message === 'undefined' || typeof message !== 'string') {
        console.error('Please provide a string message to be shown in alert.');
        return;
    }

    // 2. Reset timeout if there is an active alert
    if (isActive) {
        $ALERT.hide();
        clearTimeout(timeout);
    }

    // 3. Set alert state as active
    isActive = true;

    // 4. Append message
    $CONTENT.text(message);

    // 5. Reset state
    $ALERT.attr(ATTRIBUTE.DATA.TYPE, success ? TYPE.SUCCESS : TYPE.ERROR);

    // 6. Show alert
    $ALERT.fadeIn('slow');
    $ALERT.removeClass(CSS_CLASS.SHOWN);
    $ALERT.addClass(CSS_CLASS.SHOWN);

    // 7. Hide alert after DURATION_MS & set current state as inactive
    timeout = setTimeout(function () {
        $ALERT.fadeOut('slow');
        $ALERT.removeClass(CSS_CLASS.SHOWN);
        isActive = false;
    }, DURATION_MS);
}

/**
 * Hide the alert from viewport.
 */
function hide() {
    // Constants
    const $ALERT = $(SELECTOR.ALERT);

    // 1. Set alert state as inactive
    isActive = false;

    // 2. Hide alert
    $ALERT.fadeOut('slow');
    $ALERT.removeClass(CSS_CLASS.SHOWN);
}

/**
 * JQuery functions
 */
/**
 * Show alert component with the defined message.
 * @param {string} message - to show in alert
 * @param {boolean} success - true if is success
 */
$.alert = function (message, success) {
    show(message, success);
};

$.hideAlert = function () {
    hide();
};

'use strict';

module.exports = function (element) {
    return new Promise((resolve) => { // eslint-disable-line no-undef
        var position = element && element.length ? element.offset().top : 0;
        $('html, body').animate({ scrollTop: position }, 500, function () {
            resolve();
        });
    });
};

/**
 * Collection of scripts related with product search modal.
 */
var productSearh = {};

// Script Includes
var components = {
    datepicker: require('design/components/datepicker'),
    select: require('design/components/select')
};

// Utility Includes
var globalVars = require('design/utilities/globalVars');

// Constant Includes
var CONSTANTS = {
    MODAL: require('../constants/modals')
};

// Constants
var ATTRIBUTES = {
    CATEGORY_ID: 'cgid',
    DATEPICKER_ID: 'datepicker-id',
    HIDDEN: 'hidden',
    IS_DATE_RANGE: 'is-daterange',
    STORE_ID: 'store-id',
    URL: 'url',
    URL_STORE_SELECT: 'context-url',
    URL_SEARCH: 'search-url',
    DISABLED: 'disabled',
    DATA: {
        CATEGORY_ID: 'data-cgid',
        DATEPICKER_ID: 'data-datepicker-id',
        IS_DATE_RANGE: 'data-is-daterange',
        STORE_ID: 'data-store-id',
        URL: 'data-url'
    }
};
var CLASSES = {
    DISPLAY_NONE: 'd-none'
};
var SELECTORS = {
    CATEGORY_SELECTOR: '.comm-modal--product-search__categories__store__cgselector',
    DATEPICKER: '.comm-modal--product-search__categories__store__datepicker',
    DATEPICKER_CONTAINER:
        '.comm-modal--product-search__categories__store__datepicker .comm-search__nav__datepicker__dates',
    DATEPICKER_ID_PREFIX: '[data-datepicker-id*="product-search-modal-"]',
    DATEPICKER_INPUT: 'input[data-datepicker-id][hidden]',
    MODAL_CONTENT: '.modal-content',
    MODAL_PLACEHOLDER: '.comm-js-placeholder',
    STORE_SELECTOR: '#comm-store-selector-product-search',
    STORE: '.comm-modal--product-search__categories__store',
    DATEPICKER_WITH_EMPTY_INPUT: 'input[data-datepicker-id*="product-search-modal-"][data-empty-input="true"]'
};

/**
 * Show calendar on date picker input click.
 * @param {JQuery<HTMLElement> | null} $element - dates jquery html element component
 */
function showCalendar($element) {
    // Variables
    var $input;

    if ($element === undefined || $element === null || $element.length === 0) {
        return;
    }

    $input = $element.siblings(SELECTORS.DATEPICKER_INPUT);

    if ($input.length === 0) {
        return;
    }

    $input.trigger('click');
}

/**
 * Attach an event listener on product search model opening:
 *  1. Get modal content using and AJAX call. Possible outcomes:
 *      1.1. Success - Render it inside modal content;
 *      1.2. Error - Close modal and show an alert error message.
 */
function onShowModal() {
    $(`#${CONSTANTS.MODAL.ID.PRODUCT_SEARCH}`).on(CONSTANTS.MODAL.EVENT.SHOWN, function () {
        // Constants
        const $THIS = $(this);
        const $CONTENT = $THIS.find(SELECTORS.MODAL_CONTENT);
        const $PLACEHOLDER = $THIS.find(SELECTORS.MODAL_PLACEHOLDER);
        const URL = $THIS.data(ATTRIBUTES.URL);

        if (
            $PLACEHOLDER.length === 0 ||
            $CONTENT.length === 0 ||
            URL === undefined ||
            URL === null ||
            typeof URL !== 'string'
        ) {
            return;
        }

        $($CONTENT).spinner().start();

        $.ajax({
            url: URL,
            type: 'get',
            success: function (response, status) {
                if (status === 'success' && response && typeof response === 'string') {
                    // Variables
                    var $datePicker;

                    $PLACEHOLDER.replaceWith(response);
                    components.select.init();
                    components.datepicker.init();

                    // Init date picker input as empty for future validation
                    $datePicker = $(SELECTORS.DATEPICKER_WITH_EMPTY_INPUT);
                    if ($datePicker) {
                        $datePicker.val('');
                    }
                }
            },
            error: function (response) {
                if (
                    'responseJSON' in response &&
                    response.responseJSON !== null &&
                    'alertMessage' in response.responseJSON
                ) {
                    $THIS.modal(CONSTANTS.MODAL.ACTIONS.HIDE);
                    $.alert(response.responseJSON.alertMessage, false);
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
}

/**
 * Attach an event listener to the product search store selector.
 * When the selected store changes:
 *  1. Show selected store product search components;
 *  2. Hide the other stores product search components;
 */
function onChangeSelectedStore() {
    $('body').on('change', SELECTORS.STORE_SELECTOR, function () {
        // Constants
        const $THIS = $(this);
        const STORE_ID = $THIS.val();
        const STORE = $(`${SELECTORS.STORE}[${ATTRIBUTES.DATA.STORE_ID}=${STORE_ID}]`);
        const OTHER_STORES = $(`${SELECTORS.STORE}:not([${ATTRIBUTES.DATA.STORE_ID}=${STORE_ID}])`);
        const $DATEPICKER = $('body').find(SELECTORS.DATEPICKER_INPUT);
        const $CONFIRM_BUTTON = $(`button${SELECTORS.DATEPICKER_ID_PREFIX}`);

        // Variables
        var $hiddenDatePicker = $(SELECTORS.DATEPICKER_WITH_EMPTY_INPUT);
        var previouslySelectedDate = $hiddenDatePicker.val();
        var datepickerInput;
        var currentDatePicker;
        var currentDatePickerID;

        // 1. Show selected store product search components
        if (STORE.length) {
            STORE.removeClass(CLASSES.DISPLAY_NONE);

            // 1.1. Set the previously selected date to the category on the selected store
            datepickerInput = STORE.find('input');
            currentDatePickerID = datepickerInput.attr(ATTRIBUTES.DATA.DATEPICKER_ID);

            if (previouslySelectedDate.length) {
                currentDatePicker = globalVars.getDatePicker(currentDatePickerID);
                currentDatePicker.updateProductSearchInputValues(previouslySelectedDate);
                currentDatePicker.dateRangePicker.setStartDate(previouslySelectedDate);
                currentDatePicker.dateRangePicker.setEndDate(previouslySelectedDate);
            }
        }

        // 2. Hide the other stores
        if (OTHER_STORES.length) {
            OTHER_STORES.addClass(CLASSES.DISPLAY_NONE);
        }

        // 3. If date picker has value, enable confirm button
        if ($DATEPICKER.val().length && $CONFIRM_BUTTON) {
            $CONFIRM_BUTTON.removeAttr(ATTRIBUTES.DISABLED);
        }
    });
}

/**
 * Attach an event listener to the category selectors.
 * When the selected store changes:
 *  1. Show selected category components;
 *  2. Hide the other category components;
 */
function onChangeSelectCategory() {
    $('body').on('change', SELECTORS.CATEGORY_SELECTOR, function () {
        // Constants
        const $THIS = $(this);
        const VALUE = $THIS.val();
        const STORE_ID = VALUE.split('|')[0];
        const CATEGORY_ID = VALUE.split('|')[1];
        const DATEPICKER = $(
            `${SELECTORS.DATEPICKER}[${ATTRIBUTES.DATA.STORE_ID}=${STORE_ID}][${ATTRIBUTES.DATA.CATEGORY_ID}=${CATEGORY_ID}]`
        );
        const OTHER_DATEPICKERS = $(
            `${SELECTORS.DATEPICKER}[${ATTRIBUTES.DATA.STORE_ID}=${STORE_ID}]:not([${ATTRIBUTES.DATA.CATEGORY_ID}=${CATEGORY_ID}])`
        );

        // Variables
        var $hiddenDatePicker = $(SELECTORS.DATEPICKER_WITH_EMPTY_INPUT);
        var previouslySelectedDate = $hiddenDatePicker.val();
        var datepickerInput;
        var currentDatePicker;
        var currentDatePickerID;

        // 1. Show selected store product search components
        if (DATEPICKER.length) {
            DATEPICKER.removeClass(CLASSES.DISPLAY_NONE);

            // 1.1. Set the previously selected date to the selected category
            datepickerInput = DATEPICKER.find('input');
            currentDatePickerID = datepickerInput.attr(ATTRIBUTES.DATA.DATEPICKER_ID);

            if (previouslySelectedDate.length) {
                currentDatePicker = globalVars.getDatePicker(currentDatePickerID);
                currentDatePicker.updateProductSearchInputValues(previouslySelectedDate);
                currentDatePicker.dateRangePicker.setStartDate(previouslySelectedDate);
                currentDatePicker.dateRangePicker.setEndDate(previouslySelectedDate);
            }
        }

        // 2. Hide the other stores
        if (OTHER_DATEPICKERS.length) {
            OTHER_DATEPICKERS.addClass(CLASSES.DISPLAY_NONE);
        }
    });
}

/**
 * Attach an on click event lister to the search datepicker compoment.
 *  1. Open datepicker component
 */
function onDatePickerClick() {
    $('body').on('click', SELECTORS.DATEPICKER_CONTAINER, function (e) {
        e.preventDefault();
        showCalendar($(this));
    });
}

/**
 * Attach an event listener to all modal datepicker submit buttons.
 *  1. Get datepicker date and category;
 *  2. Redirect to the search page.
 */
function onButtonClick() {
    $('body').on('click', `button${SELECTORS.DATEPICKER_ID_PREFIX}`, function () {
        // Constants
        const $THIS = $(this);
        const DATEPICKER_ID = $THIS.data(ATTRIBUTES.DATEPICKER_ID);
        const URL = {
            SEARCH: $THIS.data(ATTRIBUTES.URL_SEARCH),
            STORE_CONTEXT: $THIS.data(ATTRIBUTES.URL_STORE_SELECT)
        };

        // Variables
        var $datepicker;
        var isDateRange;
        var dates;
        var splittedDates;
        var startDate;
        var endDate;
        var urlSeparator;

        // 1. Validate constants
        if (
            DATEPICKER_ID === null ||
            DATEPICKER_ID === undefined ||
            typeof DATEPICKER_ID !== 'string' ||
            URL.SEARCH === null ||
            URL.SEARCH === undefined ||
            typeof URL.SEARCH !== 'string' ||
            URL.STORE_CONTEXT === null ||
            URL.STORE_CONTEXT === undefined ||
            typeof URL.STORE_CONTEXT !== 'string'
        ) {
            return;
        }

        // 2. Get datepicker
        $datepicker = $(`input[${ATTRIBUTES.HIDDEN}][${ATTRIBUTES.DATA.DATEPICKER_ID}=${DATEPICKER_ID}]`);

        if ($datepicker.length === 0) {
            return;
        }

        // 2. Get dates and date related data
        isDateRange = $datepicker.data(ATTRIBUTES.IS_DATE_RANGE) === true;
        dates = $datepicker.val();

        if (dates === undefined || dates === null) {
            return;
        }

        // 3. Contextualize in selected store and redirect to search page
        $.ajax({
            url: URL.STORE_CONTEXT,
            type: 'post',
            success: function (response, status) {
                if (status === 'success' && response && 'success' in response && response.success === true) {
                    splittedDates = dates.split(' - ');
                    startDate = splittedDates.length ? splittedDates[0] : '';
                    endDate = splittedDates.length > 1 ? splittedDates[1] : '';
                    urlSeparator = URL.SEARCH.indexOf('?') === -1 ? '?' : '&';

                    // 3.1. Add date parameters (start and end dates)
                    if (isDateRange) {
                        URL.SEARCH += `${urlSeparator}startDate=${encodeURIComponent(
                            startDate
                        )}&endDate=${encodeURIComponent(endDate)}`;
                    } else {
                        URL.SEARCH += `${urlSeparator}startDate=${encodeURIComponent(startDate)}`;
                    }

                    // 3.2. Add redirect URL parameter
                    urlSeparator = URL.SEARCH.indexOf('?') === -1 ? '?' : '&';
                    URL.SEARCH += `${urlSeparator}${CONSTANTS.MODAL.PRODUCT_SEARCH.REDIRECT_PARAMETER_KEY}=${CONSTANTS.MODAL.PRODUCT_SEARCH.REDIRECT_PARAMETER_VALUE}`;

                    // 3.3 Redirect to Search page
                    window.location.href = URL.SEARCH;
                }
            },
            error: function (response) {
                if (
                    'responseJSON' in response &&
                    response.responseJSON !== null &&
                    'alertMessage' in response.responseJSON
                ) {
                    $.alert(response.responseJSON.alertMessage, false);
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
}

/**
 * Attach an event listener to when the date picker is applied.
 *  1. Enable confirm button if store selector also has a value
 */
function onDatePickerApply() {
    // Variables
    var $storeSelector;
    var $confirmButton;
    var $datePicker;
    var $hiddenDatePicker;
    var THIS;
    var datePickerID;
    var selectedDate = '';

    $('body').on('apply.daterangepicker', SELECTORS.DATEPICKER_WITH_EMPTY_INPUT, function () {
        // 1. Get the datepicker value to save on aux variable
        THIS = $(this);
        datePickerID = THIS.data(ATTRIBUTES.DATEPICKER_ID);
        $datePicker = $(`input[${ATTRIBUTES.HIDDEN}][${ATTRIBUTES.DATA.DATEPICKER_ID}=${datePickerID}]`);
        $hiddenDatePicker = $(SELECTORS.DATEPICKER_WITH_EMPTY_INPUT);
        selectedDate = $datePicker.length ? $datePicker.val() : '';

        if (selectedDate.length && $hiddenDatePicker.length) {
            $hiddenDatePicker.val(selectedDate);
        }

        // 2. If store selected, enable the confirm button
        $storeSelector = $('body').find(SELECTORS.STORE_SELECTOR);
        $confirmButton = $(`button${SELECTORS.DATEPICKER_ID_PREFIX}`);

        if ($storeSelector && $storeSelector.val() !== null && $storeSelector.val() !== undefined) {
            $confirmButton.removeAttr(ATTRIBUTES.DISABLED);
        }
    });
}

/**
 * Attach a collection of event listeners.
 */
productSearh.setEventListeners = function () {
    onShowModal();
    onChangeSelectedStore();
    onChangeSelectCategory();
    onDatePickerClick();
    onButtonClick();
    onDatePickerApply();
};

module.exports = productSearh;

var globalVars = {};

// Constants
var TYPES = {
    DATEPICKER: 'commCustomDatePickers'
};

/**
 * Get datepicker stored in window.commCustomDatePickers global variable.
 * @param {string} id - datepicker ID
 * @returns {Object | null} found datepicker or null if none is found
 */
globalVars.getDatePicker = function (id) {
    // Constants
    const DATEPICKERS = window[TYPES.DATEPICKER];

    // Variables
    var result;

    if (DATEPICKERS !== null && DATEPICKERS !== undefined && Array.isArray(DATEPICKERS) && DATEPICKERS.length) {
        result = DATEPICKERS.find(function (datepicker) {
            return datepicker.ID === id;
        });
    }

    return result;
};

module.exports = globalVars;

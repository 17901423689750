/**
 * Collection of header related functions.
 */
var header = {};

// Constants
var SELECTOR = {
    COUNTRY_SELECTOR: '.country-selector',
    HEADER: 'header',
    MINICART: '.minicart > .popover.popover-bottom',
    STORE_SELECTOR: '.ana-dropdown__airport-selector'
};
var CSS_CLASS = {
    COLLAPSED: 'ana-js-collapsed',
    SHOW: 'show'
};
var ATTRIBUTES = {
    STYLE: 'style'
};

/**
 * Verify if country selector options are shown.
 * @returns {boolean} true if country selector options are shown
 */
function isCountrySelectorShown() {
    // Constants
    const $countrySelector = $(SELECTOR.COUNTRY_SELECTOR);
    const isShown = $countrySelector.hasClass(CSS_CLASS.SHOW);
    return isShown;
}

/**
 * Verify if minicart popover is shown.
 * @returns {boolean} true if minicart popover are shown
 */
function isMiniCartShown() {
    // Constants
    const $minicart = $(SELECTOR.MINICART);
    const isShown = $minicart.hasClass(CSS_CLASS.SHOW);
    return isShown;
}

/**
 * Verify if store selector options are shown.
 * @returns {boolean} true if store selector options are shown
 */
function isStoreSelectorShown() {
    // Constants
    const $storeSelector = $(SELECTOR.STORE_SELECTOR);
    const isShown = $storeSelector.hasClass(CSS_CLASS.SHOW);
    return isShown;
}

/**
 * Verify if the header is to collapse.
 * @returns {boolean} is to collapse header validation
 */
function isToCollapse() {
    const threshold = 50;
    const isValid = $(window).scrollTop() > threshold;

    return isValid;
}

/**
 * Attach a scroll event listener to the window.
 * If the scroll is higher than 0 + threshold, the header will collapse.
 */
function onScroll() {
    $(window).scroll(function () {
        // Constants
        const $header = $(SELECTOR.HEADER);
        const height = $header.outerHeight();
        const isCollapsed = $header.hasClass(CSS_CLASS.COLLAPSED);

        // 1. Do not collapse or show header if store selector is shown
        if (isStoreSelectorShown() || isCountrySelectorShown() || isMiniCartShown()) {
            return;
        }

        // 2. Collapse or show header
        if (isToCollapse() && !isCollapsed) {
            $header.addClass(CSS_CLASS.COLLAPSED);
            $header.css('top', `-${height}px`);
        } else if (!isToCollapse() && isCollapsed) {
            $header.removeClass(CSS_CLASS.COLLAPSED);
            $header.removeAttr(ATTRIBUTES.STYLE);
        }
    });
}

/**
 * Attach all header related event listeners.
 */
header.setEventListeners = function () {
    onScroll();
};

module.exports = header;
